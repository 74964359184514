/* -------------------- dependencias -------------------- */
import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';

/*------------------- componentes --------------- */
import Axios from '../../../../../Configuracion/Axios/Axios';

/*------------------- rutas --------------- */
import { GET_URL_USER, POST_URL_VIEWS_TYPE_SEND } from '../../../../../Constancias/Rutas/Rutas';

export const TipoEnvios = (props) => {

    const back = () => {
        document.getElementById("botonesEleccionComercial").style.display = "flex";
        document.getElementById("contentCrearTipos").style.display = "none";
        document.getElementById("Tabla_Datos").style.display = "flex";
    }

    const [formData, setFormData] = useState({
        Jerarquia: '',
        agrupa: '',
        descriptivo: '',
        id_usuario: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            // Realizar la solicitud con Axios
            const response = await Axios.post(POST_URL_VIEWS_TYPE_SEND, formData);
            if (response) {
                Swal.fire({
                    icon: "success",
                    title: "Haz creado este tipo de envio correctamente",
                    showConfirmButton: false,
                    timer: 1500
                });

                setTimeout(function () {
                    window.location.reload();
                }, 1000);
            }
            // Manejar la respuesta según sea necesario
            console.log('Respuesta del servidor:', response.data);
        } catch (error) {
            // Manejar errores de la solicitud
            let err_msg = error.response.data.msg
            Swal.fire({
                title: `Ups... ${err_msg}`,
                icon: 'error',
                toast: true,
                position: 'top',
                showConfirmButton: false,
                width: 560,
                timer: 2800,
                stopKeydownPropagation: true,
                showClass: {
                    popup: 'animate__animated animate__fadeInDown'
                },
                hideClass: {
                    popup: 'animate__animated animate__fadeOutUp'
                },
                customClass: {
                    popup: 'Content_Swall_error',
                }
            });
        }
    };

    /*-------------------------------------------- traer todos los usuarios --------------------------------------------- */

    const [usuarios, setUsuarios] = useState([]);

    useEffect(() => {
        const fetchUsuarios = async () => {
            try {
                const response = await Axios.get(GET_URL_USER);

                if (response.status === 200) {
                    setUsuarios(response.data.users);
                }
            } catch (error) {
                console.error('Error en la solicitud:', error.message);
            }
        };

        fetchUsuarios();
    }, []);

    return (
        <>
            <form onSubmit={handleSubmit} className='formConfiguracion'>
                <label>
                    Jerarquía:
                    <select
                        name="Jerarquia"
                        className='selectUser'
                        value={formData.Jerarquia}
                        onChange={handleChange}
                        required
                    >
                        <option value="">Elige un nivel</option>
                        <option value="Nivel_1">Nivel 1</option>
                        <option value="Nivel_2">Nivel 2</option>
                        <option value="Nivel_3">Nivel 3</option>
                        <option value="Nivel_4">Nivel 4</option>
                    </select>
                </label>
                <label>
                    Agrupa:
                    <select
                        name="agrupa"
                        className='selectUser'
                        value={formData.agrupa}
                        onChange={handleChange}
                        required
                    >
                        <option value="">Elige un nivel</option>
                        <option value="Nivel_1">Nivel 1</option>
                        <option value="Nivel_2">Nivel 2</option>
                        <option value="Nivel_3">Nivel 3</option>
                        <option value="Nivel_4">Nivel 4</option>
                    </select>
                </label>

                <label>
                    Descriptivo:
                    <input
                        type="text"
                        name="descriptivo"
                        placeholder='Escribe el nombre'
                        value={formData.descriptivo}
                        onChange={handleChange}
                        required
                    />
                </label>

                <label>
                    Usuario a que se registrará el tipo de envio:
                    <select
                        id="id_usuario_tipo"
                        name="id_usuario"
                        value={formData.id_usuario}
                        onChange={handleChange}
                        required
                        className='selectUser'
                    >
                        <option value="" disabled>Seleccionar usuario</option>
                        {usuarios.map(usuario => (
                            <option key={usuario.id} value={usuario.id}>
                                {usuario.nombre}
                            </option>
                        ))}
                    </select>
                </label>

                <button type="submit">Crear Tipo de Envío</button>
            </form>
            <button className='backForms_1' onClick={back}>Atras</button>
        </>
    )
}