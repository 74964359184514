/* -------------------- dependencias -------------------- */
import React, { useState, useEffect } from 'react';
import './Login.css';

/*------------------- componentes --------------- */
import { Form } from '../../Funciones/LoginForm/Form';
import PantallaCarga from '../../Ui/PantallaCarga/PatallaCarga';

/*------------------- imagenes --------------- */
import imgLogin from '../../../ImagenesInterfaz/logopersonalizado.png';
import logo from '../../../ImagenesInterfaz/Logo/logo_fondo_verde.gif'
import Swal from 'sweetalert2';


/*---------------------------------- logica ---------------------------------------- */

export const Login = (props) => {

    const [loading, setLoading] = useState(true);

    // Simulando una carga
    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
            if (loading) {

                Swal.fire({
                    title: `Hola. muy pronto bitmeta tendrá una nueva aperiencia con nuevos widgets, animaciones, entre otras...`,
                    icon: 'info',
                    toast: true,
                    position: 'top',
                    timerProgressBar: true,
                    showConfirmButton: true,
                    width: 560,
                    timer: 16800,
                    stopKeydownPropagation: true,
                    showClass: {
                        popup: 'animate__animated animate__fadeInDown'
                    },
                    hideClass: {
                        popup: 'animate__animated animate__fadeOutUp'
                    },
                    customClass: {
                        popup: 'Content_Swall_info',
                        container: 'container-swall'
                    }
                })
            }
        }, 3000);
    }, [loading]);

    return (
        <>
            <div>
                {loading ? (<PantallaCarga />)
                    : (
                        <div className='mainLogin'>
                            <div className='mainImagen'>
                                <img src={imgLogin} className='sonImagen' alt="imagen de Login" />
                                <div className="shape">
                                    <img className="logoLogin" src={logo} alt="logo" />
                                </div>

                            </div>
                            <div className="background">
                                <Form />
                            </div>
                        </div>
                    )}
            </div>

        </>
    )
}