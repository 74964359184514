/* -------------------- dependencias -------------------- */
import React, { useState, useEffect } from 'react';
import './Configuracion.css'
import '../../Funciones/CrudConfiguracion/Tablas/css/tablasCss.css'
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';

/*------------------- icons --------------- */
import { FaUserCog } from "react-icons/fa";
import { FaCalendarDay } from "react-icons/fa6";
import { TbClockHour7 } from "react-icons/tb";
import { AiOutlineLineChart, AiOutlineRightCircle, AiOutlineDelete } from "react-icons/ai";
import { IoCreateOutline } from "react-icons/io5";
import { AiOutlineWechat } from "react-icons/ai";
import { RxUpdate } from "react-icons/rx";

/*------------------- sub componentes --------------- */
import Navbar from '../../Ui/Navbar/Navbar';
import Footer from '../../Ui/Footer/Footer';
import { Login } from '../Login/Login';
import { CrearPlatilla } from '../../Funciones/CrudConfiguracion/Plantillas/CrearPlantilla/CrearPlatilla';
import { EliminarPlatilla } from '../../Funciones/CrudConfiguracion/Plantillas/EliminarPlantilla/EliminarPlantilla';
import { CrearCompañias } from '../../Funciones/CrudConfiguracion/Compañias/CrearCompañias/CrearCompañias';
import { ActuaizarCompañia } from '../../Funciones/CrudConfiguracion/Compañias/ActualizarCompañias/ActualizarCompañia';
import { CrearImagenes } from '../../Funciones/CrudConfiguracion/Imagenes/CrearImagenes/CrearImagenes';
import { ActualizarImagenes } from '../../Funciones/CrudConfiguracion/Imagenes/ActualizarImagenes/ActualizarImagenes';
import { CrearUsuario } from '../../Funciones/CrudConfiguracion/Usuario/CrearUsuario/CrearUsuario';
import { ActualizarUsuario } from '../../Funciones/CrudConfiguracion/Usuario/ActualizarUsuario/ActualizarUsuario';
import { ELiminarImagenes } from '../../Funciones/CrudConfiguracion/Imagenes/EliminarImagenes/EliminarImagenes.jsx';
import { ELiminarUsuario } from '../../Funciones/CrudConfiguracion/Usuario/EliminarUsuario/EliminarUsuario.jsx';
import { ELiminarCompañias } from '../../Funciones/CrudConfiguracion/Compañias/EliminarCompañias/EliminarCompañias.jsx';

/*------------------- sub componentes tablas --------------- */
import { Usuarios } from '../../Funciones/CrudConfiguracion/Tablas/Usuarios.jsx';
import { Compañias } from '../../Funciones/CrudConfiguracion/Tablas/Compañias.jsx';
import { Imagenes } from '../../Funciones/CrudConfiguracion/Tablas/Imagenes.jsx';

/*------------------- acciones de los botones  --------------- */
import {
    dontSee,
    seeImagenes,
    seeUser,
    seeCompany,
    seePlantilla,
    crearCompañia,
    updateCompañia,
    crearUsuario,
    updateUsuario,
    crearImage,
    updateImage,
    CrearPlantillas,
    EliminarPlatillas,
    EliminarImagenes,
    botonCerrado,
    EliminarUsuarios,
    EliminarCompañia
} from './Acciones.js';



export const Configuracion = (props) => {

    /*-------------------------------------------- ejecutar funcion de reactivar los mensajes con el boton --------------------------------------------- */
    const reactivar = () => {
        document.getElementById("botonesEleccion").style.display = "none";
        Swal.fire("Ups...  este boton no esta disponible en este momento");
    }
    /*-------------------------------------------- localStorage --------------------------------------------- */
    const [ids, setId] = useState('');
    const [rolUsuario, setRol] = useState('');

    useEffect(() => {
        const obtenerUsuarioDesdeLocalStorage = () => {
            const storedObjectString = localStorage.getItem('userObject');
            if (storedObjectString) {
                const userObject = JSON.parse(storedObjectString);
                setId(userObject.auth)
                setRol(userObject.account_plan_user_role)
            } else {
                console.log('No se encontró el objeto en el localStorage');
            }
        };
        obtenerUsuarioDesdeLocalStorage();
    }, []);

    const { id, rol } = useParams();
    const urlId = parseInt(id)
    if (ids === urlId && rol === rolUsuario && rol === "superAdmin") {
        return (
            <>
                < Navbar />

                {/* ------------------------ opciones generales ------------------------------ */}

                <div className='mainContenAyuda'>
                    <div className='titleMainInicioAyuda'>
                        <h1>Configuración</h1>
                    </div><hr />
                    <div className='contentCardsDashboard'>
                        <div className="contentCardConfiguracionOptions" onClick={seeImagenes}>
                            <FaCalendarDay className='iconConfiguracion' />
                            <h3>Opciones de imágenes</h3>
                        </div>
                        <div className="contentCardConfiguracionOptions" onClick={seeUser}>
                            <FaUserCog className='iconConfiguracion' />
                            <h3>Opciones de usuario</h3>
                        </div>
                        <div className="contentCardConfiguracionOptions" onClick={seeCompany}>
                            <AiOutlineLineChart className='iconConfiguracion' />
                            <h3>Opciones de compañía</h3>
                        </div>
                        <div className="contentCardConfiguracionOptions" onClick={seePlantilla}>
                            <AiOutlineWechat className='iconConfiguracion' />
                            <h3>Opciones de plantillas </h3>
                        </div>
                        <div className="contentCardConfiguracionOptions" onClick={reactivar}>
                            <TbClockHour7 className='iconConfiguracion' />
                            <h3>Restablecer notificaciones</h3>
                        </div>
                        <div className="contentCardConfiguracionOptions" onClick={botonCerrado}>
                            <AiOutlineRightCircle className='iconConfiguracion' />
                            <h3>Ver más</h3>
                        </div>
                    </div>
                </div>

                <div id='all'>
                    {/* ------------------------ crud de imagenes opciones ------------------------------ */}
                    <div id='contentDias'>
                        <div className='contentTitle'>
                            <h1>Opciones de imagenes</h1> <p className="btnCloseVista" onClick={dontSee}>Salir</p>
                        </div><hr />

                        <div id='botonesEleccion'>
                            <div className="contentCardConfiguracionOptions" onClick={updateImage}>
                                <RxUpdate className='iconConfiguracion' />
                                <h3>Actualizar</h3>
                            </div>
                            <div className="contentCardConfiguracionOptions" onClick={crearImage}>
                                <IoCreateOutline className='iconConfiguracion' />
                                <h3>Crear</h3>
                            </div>
                            <div className="contentCardConfiguracionOptions" onClick={EliminarImagenes}>
                                <AiOutlineDelete className='iconConfiguracion' />
                                <h3>Eliminar</h3>
                            </div>
                        </div>
                        <div id='Tabla_Datos_3'>
                            <Imagenes />
                        </div>
                        <div id='contentImagenes'>
                            <CrearImagenes />
                        </div>
                        <div id='contentImagenesUpdate'>
                            <ActualizarImagenes />
                        </div>
                        <div id='contentImagenesDelete'>
                            <ELiminarImagenes />
                        </div>
                    </div>

                    {/* ------------------------ crud de usuarios opciones  ------------------------------ */}
                    <div id='contentUser'>
                        <div className='contentTitle'>
                            <h1>Opciones de usuario</h1> <p className="btnCloseVista" onClick={dontSee}>Salir</p>
                        </div><hr />

                        <div id='botonesEleccion'>
                            <div className="contentCardConfiguracionOptions" onClick={updateUsuario}>
                                <RxUpdate className='iconConfiguracion' />
                                <h3>Actualizar</h3>
                            </div>
                            <div className="contentCardConfiguracionOptions" onClick={crearUsuario}>
                                <IoCreateOutline className='iconConfiguracion' />
                                <h3>Crear</h3>
                            </div>
                            <div className="contentCardConfiguracionOptions" onClick={EliminarUsuarios}>
                                <AiOutlineDelete className='iconConfiguracion' />
                                <h3>Eliminar</h3>
                            </div>
                        </div>
                        <div id='Tabla_Datos'>
                            <Usuarios />
                        </div>
                        <div id='createUsuario'>
                            <CrearUsuario />
                        </div>
                        <div id='updateUsuario'>
                            <ActualizarUsuario />
                        </div>
                        <div id='contentUsuarioDelete'>
                            <ELiminarUsuario />
                        </div>
                    </div>

                    {/* ------------------------ crud de compañias opciones ------------------------------ */}
                    <div id='contentCompany'>
                        <div className='contentTitle'>
                            <h1>Opciones de compañía</h1> <p className="btnCloseVista" onClick={dontSee}>Salir</p>
                        </div><hr /><br />

                        <div id='botonesEleccion'>
                            <div className="contentCardConfiguracionOptions" onClick={updateCompañia}>
                                <RxUpdate className='iconConfiguracion' />
                                <h3>Actualizar</h3>
                            </div>
                            <div className="contentCardConfiguracionOptions" onClick={crearCompañia}>
                                <IoCreateOutline className='iconConfiguracion' />
                                <h3>Crear</h3>
                            </div>
                            <div className="contentCardConfiguracionOptions" onClick={EliminarCompañia}>
                                <AiOutlineDelete className='iconConfiguracion' />
                                <h3>Eliminar</h3>
                            </div>
                        </div>
                        <div id='Tabla_Datos_2'>
                            <Compañias />
                        </div>
                        <div id='crearCompañia'>
                            <CrearCompañias />
                        </div>
                        <div id='actuaizarCompañia'>
                            <ActuaizarCompañia />
                        </div>
                        <div id='contentCompañiaDelete'>
                            <ELiminarCompañias />
                        </div>
                    </div>

                    {/* ------------------------------ crud de plantilla opciones --------------------------------- */}
                    <div id='contentPlantillas'>
                        <div className='contentTitle'>
                            <h1>Opciones de plantilla</h1> <p className="btnCloseVista" onClick={dontSee}>Salir</p>
                        </div><hr /><br />
                        <div id='botonesEleccion'>
                            <div className="contentCardConfiguracionOptions" onClick={botonCerrado}>
                                <RxUpdate className='iconConfiguracion' />
                                <h3>Actualizar</h3>
                            </div>
                            <div className="contentCardConfiguracionOptions" onClick={CrearPlantillas}>
                                <IoCreateOutline className='iconConfiguracion' />
                                <h3>Crear</h3>
                            </div>
                            <div className="contentCardConfiguracionOptions" onClick={EliminarPlatillas}>
                                <AiOutlineDelete className='iconConfiguracion' />
                                <h3>Eliminar</h3>
                            </div>
                        </div>
                        <div id='CrearPlantillas'>
                            <CrearPlatilla />
                        </div>
                        <div id='EliminarPlatillas'>
                            <EliminarPlatilla />
                        </div>
                    </div>
                </div>
                < Footer />
            </>
        )
    } else {
        return (
            <Login />
        );
    }
}