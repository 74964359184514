/* -------------------- dependencias -------------------- */
import React, { useState, useEffect } from 'react';
import './Inicio.css';
/*------------------- componentes --------------- */
import { Notificaciones } from '../../Funciones/Notificaciones/Notificaciones';
import { AgregarNotificacion } from '../../Funciones/AgregarNotificacion/AgregarNotificacion';
import { BsWhatsapp } from 'react-icons/bs';
import Navbar from '../../Ui/Navbar/Navbar'
import Footer from "../../Ui/Footer/Footer";
import Axios from '../../../Configuracion/Axios/Axios';
// import { useRefresh } from '../../../Utils/Refresh'; 
import {
    GET_URL_MESSAGE
} from "../../../Constancias/Rutas/Rutas";


/*---------------------------------- logica ---------------------------------------- */

const NuevoDato = () => {
    document.getElementById("mainAgregar").style.display = "flex";
    document.getElementById("all").style.display = "flex";
    document.getElementById("mainDatosPrincipales").style.display = "flex";
}

const cerrarVista = () => {
    document.getElementById("mainAgregar").style.display = "none";
    document.getElementById("allCalendario").style.display = "none";
    document.getElementById("all").style.display = "none";
    document.getElementById("mainDatosPrincipales").style.display = "none";
}

export const Inicio = (props) => {
    const [datos, setDatos] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [tieneMensajes, setTieneMensajes] = useState(false);
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const id = parseInt(localStorage.getItem("setId"), 10);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    useEffect(() => {
        const obtenerDatosDeAPI = async () => {
          try {
            const response = await Axios.get(GET_URL_MESSAGE);
            setRefresh(false); // Cambia el estado de refresh
            setDatos(response.data.messages.reverse());
          } catch (error) {
            console.error('Error:', error);
          } finally {  
            setRefresh(true); // Restaura el estado de refresh
          }
        };
    
        obtenerDatosDeAPI(); // Llama a la función al montar el componente
        const intervalId = setInterval(obtenerDatosDeAPI, 3000); // Establece un intervalo
        return () => clearInterval(intervalId); // Limpia el intervalo al desmontar el componente
      }, [refresh, setRefresh]); 

    useEffect(() => {
        // Verificar si el usuario tiene mensajes
        const mensajesUsuario = datos.filter(data => data.id_usuario === id);
        const mensajeslogico = datos.filter(data => data.delete_logic === false);
        setTieneMensajes(mensajesUsuario.length && mensajeslogico.length > 0);
    }, [datos, id]);

    /*---------------------------------- filtros de activo e inactivo y buscador ---------------------------------------- */

    const [filtroNombre, setFiltroNombre] = useState('');
    const [filtroActivo, setFiltroActivo] = useState('');

    const filtrarDatos = () => {
        const datosFiltrados = datos.filter(item => {
            const nombreCoincide = item.nombre.toLowerCase().includes(filtroNombre.toLowerCase());
            const activoCoincide = filtroActivo === '' || (filtroActivo === 'true' ? item.is_active : !item.is_active);
            return nombreCoincide && activoCoincide;
        });
        return datosFiltrados;
    };

    return (
        <>
            <div className={`app ${isSidebarOpen ? 'expanded' : 'collapsed'}`}>
                <div className="menu-toggle" onClick={toggleSidebar}>
                    <i className="fas fa-bars"></i>
                </div>
                <Navbar />

                <div className='mainContenInicio'>
                    <div className='titleMainInicio'>
                        <h1>Tu lista de notificaciones</h1>
                        <div className='contentFilters'>
                            <input
                                className='search'
                                type="search"
                                name="nombre"
                                placeholder='Busca el nombre de tu notificación'
                                value={filtroNombre}
                                onChange={(e) => setFiltroNombre(e.target.value)}
                            />
                            <div className='mainSelect'>
                                <select
                                    className='funtionSelect'
                                    value={filtroActivo}
                                    onChange={(e) => setFiltroActivo(e.target.value)}
                                >
                                    <option value="">Todos</option>
                                    <option value={true}>Activo</option>
                                    <option value={false}>Inactivo</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <hr />
                    {tieneMensajes ? (
                        <div className='sonMain'>
                            <div className='agregarContent' onClick={NuevoDato}>
                                <BsWhatsapp className='iconAgregar' />
                                <div className='textInicio'>
                                    <p>Agregar una nueva notificación</p>
                                </div>
                            </div>
                            <div><Notificaciones datos={filtrarDatos()} setRefresh={setRefresh}/></div>
                        </div>
                    ) : (
                        <div className='sonMain'>
                            <div className='agregarContent_' onClick={NuevoDato}>
                                <BsWhatsapp className='iconAgregar' />
                                <div className='textInicio'>
                                    <p>Agregar una nueva notificación</p>
                                </div>
                            </div>
                        </div>
                    )}
                </div>

                {/* nueva notificacion  */}
                <div id='all'>
                    <div id='mainAgregar'>
                        <div className='contentTitle'>
                            <h1>Agrega una nueva notificación</h1> <p className="btnCloseVista" onClick={cerrarVista}>Salir</p>
                        </div><hr />
                        <div id='sonAgregar'>
                            <AgregarNotificacion setRefresh={setRefresh}/>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}