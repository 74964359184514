/* -------------------- dependencias -------------------- */
import React, { useState, useEffect } from 'react';
import './TusDatos.css';
/*------------------- componentes --------------- */
import Footer from '../../Ui/Footer/Footer'
import Navbar from '../../Ui/Navbar/Navbar';
import Axios from '../../../Configuracion/Axios/Axios';
import { Loading } from '../../Ui/Loading/Loading';
import { CrudRegisters } from '../TusDatos/components/crudRegister';
/*------------------- Rutas --------------- */
import {
  GET_URL_COMPANY,
  POST_URL_VIEWS_DATA,
  POST_URL_VIEWS_TYPE_SEND
} from "../../../Constancias/Rutas/Rutas";

/*------------------- imagenes --------------- */
import busqueda from "../../../ImagenesInterfaz/icon-lupa.svg";
import advertencia from "../../../ImagenesInterfaz/icon-advertencia.svg";


export const TusDatos = (props) => {

  /*------------------------------------------------ traer tipos de envios -------------------------------------------------*/
  const [views, setViews] = useState([]);
  const [companias, setCompanias] = useState([]);
  const [dataPorCompania, setDataPorCompania] = useState([]);
  const [selectedVista, setSelectedVista] = useState('');

  const handleViewsChange = (e) => {
    const selectedId = e.target.value;
    setSelectedVista(selectedId);
  };

  const idUser = parseInt(localStorage.getItem("setId"), 10);

  useEffect(() => {
    const obtenerCompanias = async () => {
      try {
        const response = await Axios.get(GET_URL_COMPANY);
        // Filtrar las compañías que coincidan con el id_usuario
        const companiasFiltradas = response.data.compañias.filter(compania => compania.id_usuario === idUser);
        // Establecer las compañías filtradas en el estado
        setCompanias(companiasFiltradas);
      } catch (error) {
        console.error('Error al obtener las compañías:', error);
      }
    };

    obtenerCompanias();
  }, [idUser]);

  /*---------------------------------- validacion de un tipo de envio por usuario ---------------------------------------- */

  useEffect(() => {
    const obtenerVistasPorCompania = async () => {
      if (companias && Array.isArray(companias) && companias.length > 0 && companias[0].id_usuario) {
        try {
          const response = await Axios.get(`${POST_URL_VIEWS_TYPE_SEND}?id_usuario=${idUser}`);
          setViews(response.data.tiposEnvio);
        } catch (error) {
          console.error('Error al obtener las vistas:', error);
        }
      }
    };
    obtenerVistasPorCompania();
  }, [companias, idUser]);

  /*------------------------------------------------ datos de esa vista -------------------------------------------------*/
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState("");
  useEffect(() => {
    const obtenerDatosPorCompania = async () => {
      if (selectedVista) {
        try {
          setLoading(true);
          const promesas = companias.map(async (compania) => {
            const data = {
              id: compania.id_usuario,
              view: selectedVista
            };
            const response = await Axios.post(POST_URL_VIEWS_DATA, data);
            setMsg(response.data.msg)
            return response.data.recipient; // Retorna solo el recipient en lugar de toda la respuesta
          });

          const recipients = await Promise.all(promesas);

          // Filtra los recipients que no son undefined
          const validRecipients = recipients.filter(recipient => recipient !== undefined);
          setDataPorCompania(validRecipients[0]);
        } catch (error) {
          console.error('Error al obtener los datos de compañía:', error);
          setMsg(error.response.data.msg)
        } finally {
          setLoading(false);
        }
      }
    };

    obtenerDatosPorCompania();
  }, [companias, selectedVista]);

  /*---------------------------------- limpieza de datos ---------------------------------------- */

  const cleanText = (text) => {
    // Reemplaza \\n con saltos de línea
    let cleanedText = text.replace(/\\n/g, '<br>');
    // Reemplaza *texto* con texto en negrita
    cleanedText = cleanedText.replace(/\*\*(.*?)\*\*/g, (match, p1) => `<strong>${p1}</strong>`);
    // Elimina cualquier otro *
    cleanedText = cleanedText.replace(/\*/g, '');
    return { __html: cleanedText.trim() };
  };

  /*---------------------------------- limpieza de datos ---------------------------------------- */

  const [see, setSeee] = useState(false)

  const see_card = () => {
    setSeee(true);


  }

  const dont_card = () => {
    setSeee(false);
    document.getElementById("crearRegistro").style.display = "none";
    document.getElementById("botonesEleccionData").style.display = "flex";
  }

  const [user, setUser] = useState('');
  console.log(user);
  useEffect(() => {
    const obtenerUsuarioDesdeLocalStorage = () => {
      const storedObjectString = localStorage.getItem('userObject');
      if (storedObjectString) {
        const userObject = JSON.parse(storedObjectString);
        setUser(userObject.nombre);
      } else {
        console.log('No se encontró el objeto en el localStorage');
      }
    };
    obtenerUsuarioDesdeLocalStorage();
  }, []);

  return (
    <>
      {user === "demo"
        ? see
          ? <CrudRegisters dontSee={dont_card} views={views} />
          : null
        : null
      }
      <Navbar />
      <div className='mainContenInicio_'>
        <div className='navGeneral'>
          <h1>Tus datos</h1>
          <div className='navFilters'>
          {user === "demo"? <div className='AddData' onClick={see_card} style={{ cursor: 'pointer' }}>Agregar un registro</div> : null}
            <select name="plantilla" className='selectDatosPrincipalesData' value={selectedVista} onChange={handleViewsChange}>
              <option value="">Tipo de envio</option>
              {views.map((view) => (
                <option key={view.id} value={view.tipo_envio}>
                  {view.descriptivo}
                </option>
              ))}
            </select>
          </div>
        </div><hr />

        {!loading ? (
          <div className='tableContainer'>
            {dataPorCompania && dataPorCompania.length > 0 && !msg ? (
              <table className='tableAll'>
                <>
                  <thead>
                    <tr>
                      <th>Mensaje</th>
                      <th>Telefono</th>
                      <th>Nombre a quien sera dirigído</th>
                    </tr>
                  </thead>
                  <tbody>

                    {dataPorCompania.map((recipient, index) => (
                      <tr key={index}>
                        <td dangerouslySetInnerHTML={cleanText(recipient.mensajes)} />
                        <td><b>{recipient.to.cel}</b></td>
                        <td><b>{recipient.to.name}</b></td>
                      </tr>
                    ))}
                  </tbody>
                </>

              </table>
            ) : (
              <div className='content-msg'>
                {msg !== ""
                  ? <div className='content-imagen-busqueda'>
                    <img src={advertencia} id='tamaño-imagen-advertencia' alt="imagen-advertencia" />
                    <div className='content-advertencia'>
                      <p>Ups... {msg}, comunicate con el equipo <a href="https://integroanalytics.my.canva.site/bitmeta#info" target='blank'>BitMeta</a> para realizar cambios. </p><br />
                      <p><b>!En el caso de crear una notificación con este tipo de envio, se protegera y no se enviará¡</b></p>
                    </div>
                  </div>
                  : <div className='content-imagen-busqueda'>
                    <img src={busqueda} alt="imagen-busqueda" />
                    <p>Busca por un tipo de envio</p>
                  </div>
                }
              </div>
            )}
          </div>
        ) : (
          <Loading />
        )}
      </div>
      <Footer />
    </>
  )
}