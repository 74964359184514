/* -------------------- dependencias -------------------- */
import React, { useState, useEffect, useRef } from 'react';
/*------------------- componentes --------------- */
import Axios from '../../../../Configuracion/Axios/Axios';
import Swal from 'sweetalert2';
import { Loading } from "../../../Ui/Loading/Loading";
/*------------------- rutas --------------- */
import {
    PUT_URL_MESSAGE,
    GET_URL_COMPANY,
    GET_URL_TEMPLATE,
    GENERAL_URL_IMAGE,
    POST_URL_VIEWS_TYPE_SEND
} from "../../../../Constancias/Rutas/Rutas";

/*---------------------------------- logica ---------------------------------------- */

export const ActualizarNoti = ({ nombre, vista, plantilla, setRefresh }) => {
    const [plantillas, setPlantillas] = useState([]);
    const [imagenes, setImagenes] = useState([]);
    const [loading, setLoading] = useState(false);

    const [nombrePlantilla, setNombrePlantilla] = useState('');
    const [selectedPlantillaId, setSelectedPlantillaId] = useState('');
    const [selectedImageUrl, setSelectedImageUrl] = useState('');

    const nombrePlantillaInput = useRef(null);
    const seleccionPlantilla = useRef(null);
    const seleccionImage = useRef(null);

    const handleNombrePlantillaChange = (e) => {
        setNombrePlantilla(e.target.value);
    };

    const handlePlantillaChange = (e) => {
        const selectedId = e.target.value;
        setSelectedPlantillaId(selectedId);
    };

    const handleImagesChange = (e) => {
        const selectedId = e.target.value;
        setSelectedImageUrl(selectedId);
    };

    const cancelUpBd = () => {
        document.getElementById("allData").style.display = "none";
    }

    /*------------------------------------------------ traer plantillas -------------------------------------------------*/

    useEffect(() => {
        // Hacer la solicitud a la API para obtener las plantillas
        let id = localStorage.getItem("setId")
        const obtenerPlantillas = async () => {
            try {
                const response = await Axios.get(`${GET_URL_TEMPLATE}?id_usuario=${id}`);
                setPlantillas(response.data.nombrePlantillas);
            } catch (error) {
                console.error('Error al obtener las plantillas:', error);
            }
        };

        obtenerPlantillas();
    }, []);

    /*------------------------------------------------ traer vistas -------------------------------------------------*/

    const [selectedVista, setSelectedVista] = useState('');
    const [companias, setCompanias] = useState([]);
    const [views, setViews] = useState([]);

    const handleViewsChange = (e) => {
        const selectedId = e.target.value;
        setSelectedVista(selectedId);
    };

    const idUsers = parseInt(localStorage.getItem("setId"), 10);

    useEffect(() => {
        const obtenerCompanias = async () => {
            try {
                const response = await Axios.get(GET_URL_COMPANY);
                const companiasFiltradas = response.data.compañias.filter(compania => compania.id_usuario === idUsers);
                setCompanias(companiasFiltradas);
            } catch (error) {
                console.error('Error al obtener las compañías:', error);
            }
        };
        obtenerCompanias();
    }, [idUsers]);

    /*---------------------------------- validacion de traer solo los tipos de un usuario logeado ---------------------------------------- */
    useEffect(() => {
        const obtenerVistasPorCompania = async () => {
            if (companias && Array.isArray(companias) && companias.length > 0 && companias[0].id_usuario) {
                try {
                    const response = await Axios.get(`${POST_URL_VIEWS_TYPE_SEND}?id_usuario=${idUsers}`);
                    setViews(response.data.tiposEnvio);
                } catch (error) {
                    console.error('Error al obtener las vistas:', error);
                }
            }
        };
        obtenerVistasPorCompania();
    }, [companias, idUsers]);


    /*-------------------------------------------- traer imagenes --------------------------------------------- */

    useEffect(() => {
        const obtenerImagenes = async () => {
            try {
                if (!companias || companias.length === 0) {
                    console.warn('Esperando respuesta de las compañias.');
                    return;
                }

                const response = await Axios.get(GENERAL_URL_IMAGE);
                const { images } = response.data;

                if (!images || images.length === 0) {
                    console.warn('Esperando respuesta de las imagenes.');
                    return;
                }

                const companiasFiltradas = images.filter(image => image.id_compania === companias[0].id);
                setImagenes(companiasFiltradas);
            } catch (error) {
                console.error('Error al obtener las imágenes:', error);
            }
        };

        obtenerImagenes();
    }, [companias]);


    /*------------------------------------------------ put de message en general -------------------------------------------------*/

    const handleActualizarNotificacion2 = async (e) => {
        const id = localStorage.getItem("codigoGenerate")
        const dataToUpdate = {
            nombre: nombrePlantilla,
            id_Plantilla: selectedPlantillaId,
            id_data_vista: selectedVista,
            url: selectedImageUrl
        };
        try {
            setLoading(true)
            // const datas = {
            //     id: id
            // };
            // const responseOff = await Axios.post(TRIGUER_POST_URL_MESSAGE_CRON, datas);
            // const responseData2 = responseOff.data;
            // console.log(responseData2);

            // if (responseOff) {
                // const queryParams = new URLSearchParams(dataToUpdate); // Convertir los datos a parámetros de consulta
                const response = await Axios.put(`${PUT_URL_MESSAGE}${id}`, dataToUpdate);
                const data = response.data;

                setTimeout(function () {
                    setRefresh(false)
                    localStorage.removeItem('codigoGenerate');
                    localStorage.removeItem('cronGenerate');
                    Swal.fire({
                        icon: 'success',
                        title: 'Se ha actualizado la notificación con exito',
                        toast: true,
                        position: 'top',
                        showConfirmButton: false,
                        width: 560,
                        timer: 2800,
                        stopKeydownPropagation: true,
                        showClass: {
                            popup: 'animate__animated animate__fadeInDown'
                        },
                        hideClass: {
                            popup: 'animate__animated animate__fadeOutUp'
                        },
                        customClass: {
                            popup: 'Content_Swall_succes_popap',
                        }
                    });
                    document.getElementById("allData").style.display = "none";
                    setLoading(false)
                }, 500);

                // console.log('Datos actualizados:', data);
                return data;
            // }

        } catch (error) {
            console.error('Error en la solicitud: ', error);
            let err = error.response.status
            let msg = error.response.data.err
            Swal.fire({
                icon: 'error',
                title: 'No podemos actualizar. Inténtalo de nuevo más tarde',
                text: `(Tu notificacion está protegida). No.error: ${err} mensaje: ${msg}`,
                customClass: {
                    container: 'Content_Swal_All'
                }
            });
            setLoading(false)
        }
    };

    /*---------------------------------- validacion de campos vacios del calendario ---------------------------------------- */

    const handleEnviarClick = () => {
        if (selectedImageUrl.trim() === '') {
            seleccionImage.current.focus();
            Swal.fire({
                title: `Escoge una imagen para este mensaje`,
                icon: 'info',
                toast: true,
                position: 'top',
                showConfirmButton: false,
                width: 560,
                timer: 2800,
                stopKeydownPropagation: true,
                showClass: {
                    popup: 'animate__animated animate__fadeInDown'
                },
                hideClass: {
                    popup: 'animate__animated animate__fadeOutUp'
                },
                customClass: {
                    popup: 'Content_Swall_error',
                }
            });
            return;
        }
        else {
            handleActualizarNotificacion2();
            document.getElementById("mainDatosPrincipales").style.display = "none";
            document.getElementById("allCalendario").style.display = "flex";
        }
    }

    // trae los datos por prosp de la vista principal de notificaciones
    const variableNombre = nombre;
    const variableVista = vista;
    const variablePlantilla = plantilla;

    return (
        <>
            {!loading ?
                <>
                    <div className='contentTitle'>
                        <h1>Actualiza los datos</h1>
                    </div>
                    <div className='sonDatosPrincipales'>
                        <div className='contentDatosPrincipales'>
                            <select name="plantilla" className='selectDatosPrincipales' value={selectedPlantillaId} onChange={handlePlantillaChange} ref={seleccionPlantilla}>
                                <option value="">{variablePlantilla}</option>
                                {plantillas.map((plantilla, index) => (
                                    <option key={index} value={plantilla}>{plantilla}</option>
                                ))}
                            </select>

                            <select name="plantilla" className='selectDatosPrincipales' value={selectedVista} onChange={handleViewsChange} >
                                <option value="">{variableVista === "1"
                                    ? "status_vendedor"
                                    : variableVista === "2"
                                        ? "status_supervisor"
                                        : variableVista === "3"
                                            ? "status_jefe" : "Tipo de envio"}  
                                </option>
                                {views.map((view) => (
                                    <option key={view.id} value={view.tipo_envio}>
                                        {view.descriptivo}
                                    </option>
                                ))}
                            </select>

                            <select name="plantilla" className='selectDatosPrincipales' value={selectedImageUrl} onChange={handleImagesChange} ref={seleccionImage}>
                                <option value="">Imagenes</option>
                                {imagenes.map((images, index) => (
                                    <option key={index} value={images.url}>{images.nombre_foto}</option>
                                ))}
                            </select>

                            <input
                                className='inputDatosPrincipales'
                                type="text"
                                maxLength={27}
                                placeholder={variableNombre}
                                value={nombrePlantilla}
                                onChange={handleNombrePlantillaChange}
                                ref={nombrePlantillaInput}
                                required
                            />
                            <div className='btndatos'>
                                <button className='btn1' id='btn1' onClick={handleEnviarClick}>Actualizar</button>
                                <button className='btn1' onClick={cancelUpBd}>Salir</button>
                            </div>
                        </div>
                    </div>
                </> 
                : <div className='mainCalendario_2'>
                    <Loading />
                </div>}
        </>
    )
}