/* -------------------- dependencias -------------------- */
import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';

/*------------------- componentes --------------- */
import Axios from '../../../../../Configuracion/Axios/Axios';

/*------------------- rutas --------------- */
import { GET_URL_USER } from '../../../../../Constancias/Rutas/Rutas';


export const ELiminarUsuario = (props) => {

    const back = () => {
        document.getElementById("botonesEleccion").style.display = "flex";
        document.getElementById("contentUsuarioDelete").style.display = "none";
        document.getElementById("Tabla_Datos").style.display = "flex";
    }

    /*-------------------------------------------- traer todos los usuarios --------------------------------------------- */

    const [usuarios, setUsuarios] = useState([]);
    const [usuariosFilter, setUsuariosFilter] = useState([]);
    const [idUser, setIdUser] = useState('');

    const handleChangeUserUp = (event) => {
        const selectedUserId = event.target.value;
        setIdUser(selectedUserId);
    };

    useEffect(() => {
        const fetchUsuarios = async () => {
            try {
                const response = await Axios.get(GET_URL_USER);
                if (response.status === 200) {
                    setUsuarios(response.data.users);
                    setUsuariosFilter(response.data.users.filter((user) => user.id === parseInt(idUser, 10)));
                }
            } catch (error) {
                console.warn('Error en la solicitud:', error.message);
            }
        };

        fetchUsuarios();
    }, [idUser]);

    const handleEliminarUsuario = async (e) => {
        e.preventDefault();
        try {
            const correo = usuariosFilter[0].correo;
            const response = await Axios.delete(GET_URL_USER, { data: { correo } });

            if (response.status === 200) {
                Swal.fire({
                    icon: "success",
                    title: "Haz desactivado el usuario correctamente",
                    showConfirmButton: false,
                    timer: 1500
                });

                setTimeout(function () {
                    window.location.reload();
                }, 1000);
            }
        } catch (error) {
            console.warn('Error en la solicitud:', error.message);
            Swal.fire({
                title: `Ups... hubo un problema, revisa bien los parametros`,
                icon: 'error',
                toast: true,
                position: 'top',
                showConfirmButton: false,
                width: 560,
                timer: 2800,
                stopKeydownPropagation: true,
                showClass: {
                    popup: 'animate__animated animate__fadeInDown'
                },
                hideClass: {
                    popup: 'animate__animated animate__fadeOutUp'
                },
                customClass: {
                    popup: 'Content_Swall_error',
                }
            });
        }
    };



    return (
        <>
            <form onSubmit={handleEliminarUsuario} className='formConfiguracion'>
                <label htmlFor="id_usuario">Escoge el usuario que quieras editar:</label>
                <select
                    id="id_usuariodelete"
                    name="id_usuariodelete"
                    value={idUser}
                    onChange={handleChangeUserUp}
                    required
                    className='selectUser'
                >
                    <option value="" disabled>Seleccionar usuario</option>
                    {usuarios.map(usuario => (
                        <option key={usuario.id} value={usuario.id}>
                            {usuario.nombre}
                        </option>
                    ))}
                </select>
                <button type="submit">Desactivar usuario</button>
            </form>
            <button className='backForms' onClick={back}>Atras</button>
        </>
    )
}