/* -------------------- dependencias -------------------- */
import './DashboardContent.css'
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
/*------------------- componentes --------------- */
import Axios from '../../../Configuracion/Axios/Axios';
import { Loading } from '../../Ui/Loading/Loading';
/*------------------- Rutas --------------- */
import {
    GET_URL_MESSAGE,
    GET_URL_USER
} from "../../../Constancias/Rutas/Rutas";
/*------------------ iconos --------------- */
import { CiBookmarkCheck } from "react-icons/ci";
import { FaMoneyCheck } from "react-icons/fa";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { RxReload } from "react-icons/rx";
import { AiOutlineClockCircle } from "react-icons/ai";
import { IoCheckmarkCircleOutline } from "react-icons/io5";

export const DashboardContent = (props) => {

    /*---------------------------------- total de mensajes enviados ---------------------------------------- */

    const [datos, setDatos] = useState([]);
    let conteo = datos.length
    const id = parseInt(localStorage.getItem("setId"), 10);
    const [contador, setContador] = useState(0);

    useEffect(() => {
        const handleEstadoChange = () => {
            // Filtra los elementos cuyo estado es igual a 0 y cuenta el número resultante
            const nuevoContador = datos.filter((dato) => dato.estado === 0 && dato.delete_logic === false).length;
            setContador(nuevoContador);
        };

        // Llamada inicial a handleEstadoChange dentro de useEffect
        handleEstadoChange();
    }, [datos]);

    /*---------------------------------- conteo de mensajes totales enviados ---------------------------------------- */

    useEffect(() => {
        const obtenerDatosDeAPI = async () => {
            try {
                const response = await Axios.get(GET_URL_MESSAGE);
                const filtro = response.data.messages.filter((item) => item.delete_logic === false)
                const usuario = filtro.filter((item) => item.id_usuario === id)
                setDatos(usuario);
            } catch (error) {
                console.error('Error:', error);
            }
        };
        obtenerDatosDeAPI();
    }, [id]);

    /*---------------------------------- usuario logeado ---------------------------------------- */

    // const storedUserObject = JSON.parse(localStorage.getItem("userObject"));countDemo
    // const userId = parseInt(localStorage.getItem("setId"), 10);

    const [idUser, setIdUser] = useState([]);
    const [auth, setAunt] = useState([]);
    useEffect(() => {
        const obtenerUsuarioDesdeLocalStorage = () => {
            const storedObjectString = localStorage.getItem('userObject');
            if (storedObjectString) {
                const userObject = JSON.parse(storedObjectString);
                setIdUser(userObject.auth)
                setAunt(userObject.account_plan_name)
            } else {
                return;
            }
        };
        obtenerUsuarioDesdeLocalStorage();
    }, []);
    const [userObject, setUserObject] = useState(null);

    /*---------------------------------- validar si existe ese usuario ---------------------------------------- */

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await Axios.get(GET_URL_USER);
                const userData = response.data.users.filter((user) => user.id === idUser);

                if (userData.length > 0) {
                    setUserObject(userData[0]);
                } else {
                    return;
                }
            } catch (error) {
                console.error('Error al obtener datos:', error);
            }
        };
        fetchData();
    }, [idUser]);


    /*---------------------------------- content de reportes ---------------------------------------- */

    const Reportes = () => {
        // document.getElementById("all").style.display = "flex"
        document.getElementById("ContentReporteNotification").style.display = "flex"
    }

    const ReporteEnvio = () => {
        // document.getElementById("all").style.display = "flex"       
        document.getElementById("ContentEnvios").style.display = "flex"
    }

    return (
        <>
            {userObject ? (
                <>
                    <div className='contentCardsDashboard'>
                        <Link to="/inicio" className="contentCardOptios">
                            <CiBookmarkCheck className='icon-Daskboard' />
                            <h3>Notificaciones actuales</h3>
                            <p className='conteoText'>{conteo}</p>
                        </Link>

                        <div className="contentCardOptios">
                            <AiOutlineClockCircle className='icon-Daskboard' />
                            <h3>Notificaciones programadas</h3>
                            <p className='conteoText'>{contador}</p>
                        </div>
                        <div className="contentCardOptios">
                            <AiOutlineExclamationCircle className='icon-Daskboard' />
                            <h3>Notificaciones restantes </h3>
                            <p className='conteoText'>{userObject.countDemo}</p>
                        </div>
                        <div className="contentCardOptios" onClick={Reportes}>
                            <RxReload className='icon-Daskboard' />
                            <h3>Estado de Notificaciones</h3>
                        </div>
                        <div className="contentCardOptios">
                            <FaMoneyCheck className='icon-Daskboard' />
                            <h3>Plan de la cuenta</h3>
                            <p className='conteoText'>{auth}</p>
                        </div>
                        <div className="contentCardOptios" onClick={ReporteEnvio}>
                            <IoCheckmarkCircleOutline className='icon-Daskboard2' />
                            <h3>Reportes de envio</h3>
                        </div>
                    </div>
                </>
            ) : (
                <Loading />
            )}
        </>
    )
}