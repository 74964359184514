/* -------------------- dependencias -------------------- */
import React, { useEffect, useState } from 'react';

/*------------------- componentes --------------- */
import Axios from '../../../../Configuracion/Axios/Axios';

/*------------------- imagenes --------------- */
import { POST_URL_VIEWS_TYPE_SEND } from '../../../../Constancias/Rutas/Rutas';

export const TablaTipoEnvios = (props) => {

    const [datos, setDatos] = useState([]);

    useEffect(() => {
      Axios.get(POST_URL_VIEWS_TYPE_SEND)
        .then((response) => {
          setDatos(response.data.tiposEnvio.reverse());
        })
        .catch((error) => {
          console.error('Error al obtener los datos: ', error);
        });
    }, []);

    return (
        <>
            <table className='tabla-tipoEnvio'>
                <thead>
                    <tr>
                        <th>Tipo de Envío</th>
                        <th>Jerarquía</th>
                        <th>Agrupación</th>
                        <th>Descriptivo</th>
                        <th>Usuario</th>
                    </tr>
                </thead>
                <tbody>
                    {datos.map((item, index) => (
                        <tr key={index}>
                            <td>{item.tipo_envio}</td>
                            <td>{item.Jerarquia}</td>
                            <td>{item.agrupa}</td>
                            <td>{item.descriptivo}</td>
                            <td>{item.id_usuario}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    )
}