/* -------------------- dependencias -------------------- */
import React, { useState, useEffect, useRef } from 'react';
import './AgregarNotificacion.css'
import { AiOutlineWhatsApp } from "react-icons/ai";
import { BsCalendarPlus } from "react-icons/bs";
import Select from 'react-select';
import Swal from 'sweetalert2';
// import cronstrue from 'cronstrue';
/*------------------- componentes --------------- */
import Axios from '../../../Configuracion/Axios/Axios';
// import { IphoneRender } from '../../Ui/IphoneRender/IphoneRender';
import { Loading } from '../../Ui/Loading/Loading';
/*------------------- Rutas --------------- */
import {
    PUT_URL_MESSAGE,
    POST_URL_MESSAGE,
    GET_URL_COMPANY,
    GET_URL_HOURS,
    GET_URL_WEEK,
    GET_URL_TEMPLATE,
    GENERAL_URL_IMAGE,
    POST_URL_VIEWS_TYPE_SEND
} from "../../../Constancias/Rutas/Rutas";

export const AgregarNotificacion = ({ setRefresh }) => {

    /*-------------------------------------------------- variables useState ---------------------------------------- */

    const [plantillas, setPlantillas] = useState([]);
    const [views, setViews] = useState([]);
    const [companias, setCompanias] = useState([]);
    const [imagenes, setImagenes] = useState([]);
    const [diasDeLaSemana, setDiasDeLaSemana] = useState([]);
    const [horasDelDia, setHorasDelDia] = useState([]);
    const [diasSeleccionados, setDiasSeleccionados] = useState([]);
    const [horasSeleccionadas, setHorasSeleccionadas] = useState([]);

    /*---------------------------------- ref de los elementos html ---------------------------------------- */

    const [nombrePlantilla, setNombrePlantilla] = useState('');
    const [selectedPlantillaId, setSelectedPlantillaId] = useState('');
    const [selectedVista, setSelectedVista] = useState('');
    const [selectedImageUrl, setSelectedImageUrl] = useState('');

    /*---------------------------------- ref de los elementos html ---------------------------------------- */

    const nombrePlantillaInput = useRef(null);
    const seleccionImage = useRef(null);
    const seleccionView = useRef(null);
    const seleccionPlantilla = useRef(null);
    const seleccionHoras = useRef(null);
    const seleccionSemana = useRef(null);

    /*---------------------------------- optecion de datos de los elementos html ---------------------------------------- */

    const handleNombrePlantillaChange = (e) => {
        setNombrePlantilla(e.target.value);
    };
    const handlePlantillaChange = (e) => {
        const selectedId = e.target.value;
        setSelectedPlantillaId(selectedId);
    };
    const handleImagesChange = (e) => {
        const selectedId = e.target.value;
        setSelectedImageUrl(selectedId);
    };
    const handleViewsChange = (e) => {
        const selectedId = e.target.value;
        setSelectedVista(selectedId);
    };

    /*---------------------------------- usuario logeado ---------------------------------------- */

    const [idUser, setIdUser] = useState([]);

    useEffect(() => {
        const obtenerUsuarioDesdeLocalStorage = () => {
            const storedObjectString = localStorage.getItem('userObject');
            if (storedObjectString) {
                const userObject = JSON.parse(storedObjectString);
                setIdUser(userObject.auth)
            } else {
                console.log('No se encontró el objeto en el localStorage');
            }
        };
        obtenerUsuarioDesdeLocalStorage();
    }, []);

    /*------------------------------------------------ traer plantillas -------------------------------------------------*/

    useEffect(() => {
        // Hacer la solicitud a la API para obtener las plantillas
        let id = localStorage.getItem("setId")
        const obtenerPlantillas = async () => {
            try {
                const response = await Axios.get(`${GET_URL_TEMPLATE}?id_usuario=${id}`);
                setPlantillas(response.data.nombrePlantillas);
            } catch (error) {
                console.error('Error al obtener las plantillas:', error);
            }
        };

        obtenerPlantillas();
    }, []);

    /*------------------------------------------------ traer vistas -------------------------------------------------*/

    const idUsers = parseInt(localStorage.getItem("setId"), 10);

    useEffect(() => {
        const obtenerCompanias = async () => {
            try {
                const response = await Axios.get(GET_URL_COMPANY);
                const companiasFiltradas = response.data.compañias.filter(compania => compania.id_usuario === idUsers);
                setCompanias(companiasFiltradas);
            } catch (error) {
                console.error('Error al obtener las compañías:', error);
            }
        };

        obtenerCompanias();
    }, [idUsers]);

    /*---------------------------------- validacion de traer solo los tipos de un usuario logeado ---------------------------------------- */
    useEffect(() => {
        const obtenerVistasPorCompania = async () => {
            if (companias && Array.isArray(companias) && companias.length > 0 && companias[0].id_usuario) {
                try {
                    const response = await Axios.get(`${POST_URL_VIEWS_TYPE_SEND}?id_usuario=${idUsers}`);
                    setViews(response.data.tiposEnvio);
                } catch (error) {
                    console.error('Error al obtener las vistas:', error);
                }
            }
        };
        obtenerVistasPorCompania();
    }, [companias, idUsers]);

    /*-------------------------------------------- traer imagenes --------------------------------------------- */

    useEffect(() => {
        const obtenerImagenes = async () => {
            try {
                if (!companias || companias.length === 0) {
                    console.warn('Esperando respuesta de las compañias.');
                    return;
                }

                const response = await Axios.get(GENERAL_URL_IMAGE);
                const { images } = response.data;

                if (!images || images.length === 0) {
                    console.warn('Esperando respuesta de las imagenes.');
                    return;
                }

                const companiasFiltradas = images.filter(image => image.id_compania === companias[0].id);
                setImagenes(companiasFiltradas);
            } catch (error) {
                console.error('Error al obtener las imágenes:', error);
            }
        };

        obtenerImagenes();
    }, [companias]);

    /*------------------------------------------------ post mensaje -------------------------------------------------*/

    const enviarDatos = async () => {
        try {
            const response = await Axios.post(POST_URL_MESSAGE, {
                nombre: nombrePlantilla,
                id_Plantilla: selectedPlantillaId,
                id_usuario: idUser,
                id_data_vista: selectedVista,
                url: selectedImageUrl
            });

            // console.log('Datos enviados:', response.data);
            localStorage.setItem("codigoGenerate", response.data.message.id)
            // window.location.reload();
        } catch (error) {
            console.error('Error al enviar los datos:', error);
        }
    };

    /*------------------------------------------------ alerta de mensajes vacios -------------------------------------------------*/

    const handleEnviarClick = () => {
        if (selectedPlantillaId === '') {
            seleccionPlantilla.current.focus();
            Swal.fire({
                title: `Selecciona una plantilla para este mensaje`,
                icon: 'info',
                toast: true,
                position: 'top',
                showConfirmButton: false,
                width: 560,
                timer: 2800,
                stopKeydownPropagation: true,
                showClass: {
                    popup: 'animate__animated animate__fadeInDown'
                },
                hideClass: {
                    popup: 'animate__animated animate__fadeOutUp'
                },
                customClass: {
                    popup: 'Content_Swall_error',
                }
            });
            return;
        } else if (seleccionView.current.value.trim() === '') {
            seleccionView.current.focus();
            Swal.fire({
                title: `Selecciona una vista para este mensaje`,
                icon: 'info',
                toast: true,
                position: 'top',
                showConfirmButton: false,
                width: 560,
                timer: 2800,
                stopKeydownPropagation: true,
                showClass: {
                    popup: 'animate__animated animate__fadeInDown'
                },
                hideClass: {
                    popup: 'animate__animated animate__fadeOutUp'
                },
                customClass: {
                    popup: 'Content_Swall_error',
                }
            });
            return;
        } else if (selectedImageUrl.trim() === '') {
            seleccionImage.current.focus();
            Swal.fire({
                title: `Selecciona una imagen para este mensaje`,
                icon: 'info',
                toast: true,
                position: 'top',
                showConfirmButton: false,
                width: 560,
                timer: 2800,
                stopKeydownPropagation: true,
                showClass: {
                    popup: 'animate__animated animate__fadeInDown'
                },
                hideClass: {
                    popup: 'animate__animated animate__fadeOutUp'
                },
                customClass: {
                    popup: 'Content_Swall_error',
                }
            });
            return;
        } else if (nombrePlantilla.trim() === '') {
            nombrePlantillaInput.current.focus();
            Swal.fire({
                title: `Escribe un nombre para este mensaje`,
                icon: 'info',
                toast: true,
                position: 'top',
                showConfirmButton: false,
                width: 560,
                timer: 2800,
                stopKeydownPropagation: true,
                showClass: {
                    popup: 'animate__animated animate__fadeInDown'
                },
                hideClass: {
                    popup: 'animate__animated animate__fadeOutUp'
                },
                customClass: {
                    popup: 'Content_Swall_error',
                }
            });
            return;
        } else {
            enviarDatos();
            setNombrePlantilla('');
            setSelectedPlantillaId('');
            setSelectedVista('');
            setSelectedImageUrl('');
            document.getElementById("mainDatosPrincipales").style.display = "none";
            document.getElementById("allCalendario").style.display = "flex";
        }
    };
    /*------------------------------------------------ ejecutar calendario -------------------------------------------------*/

    const handleEnviarClick2 = () => {
        setNombrePlantilla('');
        setSelectedPlantillaId('');
        setSelectedVista('');
        setSelectedImageUrl('');
        document.getElementById("mainDatosPrincipales").style.display = "none";
        document.getElementById("allCalendario").style.display = "none";
        document.getElementById("all").style.display = "none";
    };

    // const [diasSeleccionados, setDiasSeleccionados] = useState([]);
    // const [horasSeleccionadas, setHorasSeleccionadas] = useState([]);}
    /*------------------------------------------------ traer los dato para codigo cron dias y horas -------------------------------------------------*/

    useEffect(() => {
        const obtenerDiasDeLaSemana = async () => {
            try {
                const response = await Axios.get(GET_URL_WEEK);
                setDiasDeLaSemana(response.data.table.map(dia => ({ value: dia.id, label: dia.dias })));
            } catch (error) {
                console.error('Error al obtener días de la semana desde la API:', error);
            }
        };

        const obtenerHorasDelDia = async () => {
            try {
                const response = await Axios.get(GET_URL_HOURS);
                setHorasDelDia(response.data.table.map(hora => ({ value: hora.cod, label: hora.horas })));
            } catch (error) {
                console.error('Error al obtener horas del día desde la API:', error);
            }
        };

        obtenerDiasDeLaSemana();
        obtenerHorasDelDia();
    }, []);

    /*-------------------------------------------- actualizar para publicar el cron en el mensaje --------------------------------------------- */

    const [loading, setLoading] = useState(false);
    const handleActualizarNotificacion = async (e) => {

        // le mando el calendario seteado construido 
        const diasSeleccionadosStr2 = diasSeleccionados.map(dias => dias.label).join(', ');
        const horasSeleccionadasStr2 = horasSeleccionadas.map(horas => horas.label).join(', ');
        const calendario = "Esta notificación ejecutará. " + diasSeleccionadosStr2 + " a las " + horasSeleccionadasStr2;

        const id = localStorage.getItem("codigoGenerate")

        if (calendario) {
            const dataToUpdate = {
                codigo_cron: localStorage.getItem("cronGenerate"),
                cron_active: true,
                calendario: calendario
            };

            try {
                // const queryParams = new URLSearchParams(dataToUpdate); // Convertir los datos a parámetros de consulta
                const response = await Axios.put(`${PUT_URL_MESSAGE}${id}`, dataToUpdate);
                const data = response.data;
                setLoading(true)

                if (data) {
                    setTimeout(function () {
                        setRefresh(false)
                        localStorage.removeItem('codigoGenerate');
                        localStorage.removeItem('cronGenerate');
                        setNombrePlantilla('');
                        setSelectedPlantillaId('');
                        setSelectedVista('');
                        setSelectedImageUrl('');
                        setLoading(false);
                        // document.getElementById("allCalendario").style.display = "none";
                        document.getElementById("all").style.display = "none";
                    }, 1000);
                }
                // console.log('Datos actualizados:', data);
                return data;
            } catch (error) {
                setLoading(false);
                console.error('Error en la solicitud: ', error);
            }
        } else {
            alert('nombre de calendario esta vacio')
        }
    };

    /*-------------------------------------------- construyo el calendario codigo cron --------------------------------------------- */

    const handleEnviarCron = () => {
        /*-------------------------------------------- validacion de campos vacios de calendario --------------------------------------------- */
        if (!diasSeleccionados || diasSeleccionados.length === 0 || !horasSeleccionadas || horasSeleccionadas.length === 0) {
            if (seleccionSemana.current) {
                Swal.fire({
                    title: `Selecciona los días y horas para continuar`,
                    icon: 'info',
                    toast: true,
                    position: 'top',
                    showConfirmButton: false,
                    width: 560,
                    timer: 2800,
                    stopKeydownPropagation: true,
                    showClass: {
                        popup: 'animate__animated animate__fadeInDown'
                    },
                    hideClass: {
                        popup: 'animate__animated animate__fadeOutUp'
                    },
                    customClass: {
                        popup: 'Content_Swall_error',
                    }
                });
            }
            return;
        }

        const diasSeleccionadosStr = diasSeleccionados.map(dias => dias.value).join(',');
        const horasSeleccionadasStr = horasSeleccionadas.map(horas => horas.value).join(',');
        const cronExpresion = `0 ${horasSeleccionadasStr} * * ${diasSeleccionadosStr}`;
        // const cronExpresionLegible = cronstrue.toString(cronExpresion);

        // Aquí podrías enviar la expresión cron a tu API
        localStorage.setItem("cronGenerate", cronExpresion)
        // console.log('Expresión cron generada:', cronExpresion);
        // console.log('Expresión cron legible:', cronExpresionLegible);
        handleActualizarNotificacion();
    };

    return (
        <>
            {loading ?
                <Loading />
                : <>
                    <div id='mainDatosPrincipales'>
                        <div className='sonDatosPrincipales'>
                            <div className='contentDatosPrincipales'>
                                <select name="plantilla" className='selectDatosPrincipales' value={selectedPlantillaId} onChange={handlePlantillaChange} ref={seleccionPlantilla}>
                                    <option value="">Plantillas</option>
                                    {plantillas.map((plantilla, index) => (
                                        <option key={index} value={plantilla}>{plantilla}</option>
                                    ))}
                                </select>

                                <select name="view" className='selectDatosPrincipales' value={selectedVista} onChange={handleViewsChange} ref={seleccionView}>
                                    <option value="">Tipo de envio</option>
                                    {views.map((view) => (
                                        <option key={view.id} value={view.tipo_envio}>
                                            {view.descriptivo}
                                        </option>
                                    ))}
                                </select>

                                <select name="images" className='selectDatosPrincipales' value={selectedImageUrl} onChange={handleImagesChange} ref={seleccionImage}>
                                    <option value="">Imagenes</option>
                                    {imagenes.map((images, index) => (
                                        <option key={index} value={images.url} >{images.nombre_foto}</option>
                                    ))}
                                </select>

                                <input
                                    className='inputDatosPrincipales'
                                    type="text"
                                    maxLength={23}
                                    placeholder='Nombre de la plantilla'
                                    value={nombrePlantilla}
                                    onChange={handleNombrePlantillaChange}
                                    ref={nombrePlantillaInput}
                                    required
                                />

                                <div className='btndatos'>
                                    <button className='btn1' id='btn1' onClick={handleEnviarClick}>Agregar una fecha</button>
                                </div>
                            </div>

                            <div className='Iphone-content'>
                                    <AiOutlineWhatsApp  className='iconDatosPrincipales' />
                                {/* <IphoneRender idUsers={idUsers} selectedImageUrl={selectedImageUrl} selectedVista={selectedVista} nombrePlantilla={nombrePlantilla} selectedPlantillaId={selectedPlantillaId} /> */}
                            </div>
                        </div>
                    </div>

                    <div id='allCalendario'>
                        <BsCalendarPlus className='icon2DatosPrincipales' />
                        <div id='mainCalendario'>

                            <h2>Selecciona horas del día:</h2>
                            <Select
                                options={horasDelDia}
                                isMulti
                                className='selectCalendario'
                                placeholder='Horas'
                                value={horasSeleccionadas}
                                onChange={setHorasSeleccionadas}
                                ref={seleccionHoras}
                            />

                            <h2>Selecciona días de la semana:</h2>
                            <Select
                                options={diasDeLaSemana}
                                isMulti
                                className='selectCalendario'
                                placeholder='Días'
                                value={diasSeleccionados}
                                onChange={setDiasSeleccionados}
                                ref={seleccionSemana}
                            />

                            <button className='btn1' onClick={handleEnviarCron}>Agregar Calendario</button>
                            <button className='btn1' onClick={handleEnviarClick2}>Cancelar</button>
                        </div>
                    </div>
                </>
            }
        </>
    )
}