/* -------------------- dependencias -------------------- */
import React from 'react';
import './Ayuda.css'

/* -------------------- iconos -------------------- */
import { AiOutlineClockCircle, AiOutlineDelete , AiOutlineCloseCircle, AiOutlineCheckCircle} from "react-icons/ai";
import { BsDatabaseExclamation, BsDatabaseCheck } from "react-icons/bs";
import { FaPlay } from "react-icons/fa"
import { CiCircleQuestion } from "react-icons/ci";
/*------------------- componentes --------------- */
import Navbar from '../../Ui/Navbar/Navbar';
import Footer from '../../Ui/Footer/Footer';

export const Ayuda = (props) => {
    return (
        <>
            <Navbar />
            <div className='mainContenAyuda'>
                <div className='titleMainInicioAyuda'>
                    <h1>Ayuda</h1>
                </div><hr />

                <div className="attributes">
                    <div className="attribute">
                        <FaPlay className='iconCardTamplatePlay' />
                        <p><strong>Reproducir:</strong> Puedes hacer una prueba del envío del mensaje "tener en cuenta el plan que tienes adquirido"</p>
                    </div>
                    <div className="attribute">
                        <AiOutlineCloseCircle className='iconCardTamplate3' />
                        <p><strong>Desactivar notificación:</strong> Con esta acción puedes desactivar la notidicacion cuando quieras</p>
                    </div>
                    <div className="attribute">
                        <AiOutlineCheckCircle className='iconCardTamplate6_1' />
                        <p><strong>Activar notificación:</strong> Con esta acción puedes activar la notidicacion cuando quieras</p>
                    </div>
                    <div className="attribute">
                        <AiOutlineClockCircle className='iconCardTamplate6' />
                        <p><strong>Tiempo:</strong> El botón de reloj te esta avisando de que no tiene un horario para poder enviar </p>
                    </div>
                    <div className="attribute">
                        <AiOutlineClockCircle className='iconCardTamplate6_1 ' />
                        <p><strong>Tiempo:</strong> El botón de reloj te esta avisando de que si tiene un horario para poder enviar</p>
                    </div>
                    <div className="attribute">
                        <BsDatabaseExclamation className='iconCardTamplate2_1' />
                        <p><strong>Base de datos (Alerta):</strong> Problemas o alertas de que no se a añadido a la notificación una base de datos.</p>
                    </div>
                    <div className="attribute">
                        <BsDatabaseCheck className='iconCardTamplate5_1' />
                        <p><strong>Base de datos (Éxito):</strong> Éxito o confirmación  de que se a añadido a la notificación una base de datos.</p>
                    </div>
                    {/* <div className="attribute">
                        <BsFillPauseFill className='iconCardTamplatePause'/>
                        <p><strong>Pausa:</strong> Indica una pausa o interrupción.</p>
                    </div> */}
                    <div className="attribute">
                        <AiOutlineDelete className='iconCardTamplate3' />
                        <p><strong>Eliminar:</strong> Indica la eliminación inmediata de la notificación "Si la borraste accidetalmente no puedes volver a ver esa notificación"</p>
                    </div>
                </div>

                <div className='IconoDuda'>
                    <CiCircleQuestion />
                </div>
            </div>
            <Footer />
        </>
    )
}