// IconoActivacion.js
import { AiOutlineCloseCircle, AiOutlineCheckCircle } from 'react-icons/ai';
import React, { useState } from 'react';

const IconoActivacion = ({ isActive, onClick }) => {

    /*---------------------------------- ayuda viauales para los botones de activacion ---------------------------------------- */

    const TarjetaOn = ({ data }) => {
        const [isHovered, setIsHovered] = useState(false);

        const handleMouseEnter = () => {
            setIsHovered(true);
        };

        const handleMouseLeave = () => {
            setIsHovered(false);
        };

        return (
            <div style={{ position: 'relative' }}>
                <AiOutlineCloseCircle
                    className={`iconCardTamplate3 ${isHovered ? 'hovered' : ''}`}
                    onClick={onClick}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                />
                {isHovered && (
                    <div className="hoverIcon">
                        <p className='textHoverIcon'>Desactivar</p>
                    </div>
                )}
            </div>
        );
    };

    const TarjetaOff = ({ data }) => {
        const [isHovered, setIsHovered] = useState(false);

        const handleMouseEnter = () => {
            setIsHovered(true);
        };

        const handleMouseLeave = () => {
            setIsHovered(false);
        };

        return (
            <div style={{ position: 'relative' }}>
                <AiOutlineCheckCircle
                    className={`iconCardTamplate5_1 ${isHovered ? 'hovered' : ''}`}
                    onClick={onClick}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                />
                {isHovered && (
                    <div className="hoverIcon">
                        <p className='textHoverIcon'>Activar</p>
                    </div>
                )}
            </div>
        );
    };

    return isActive ? (
        // <AiOutlineCloseCircle className='iconCardTamplate3' onClick={onClick} />
        <TarjetaOn />

    ) : (
        // <AiOutlineCheckCircle className='iconCardTamplate5_1' onClick={onClick} />
        <TarjetaOff />

    );
};

export default IconoActivacion;
