/* -------------------- dependencias -------------------- */
import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';

/*------------------- componentes --------------- */
import Axios from '../../../../../Configuracion/Axios/Axios';

/*------------------- rutas --------------- */
import { GENERAL_URL_IMAGE, GET_URL_COMPANY } from '../../../../../Constancias/Rutas/Rutas';


/*---------------------------------- logica ---------------------------------------- */

export const CrearImagenes = (props) => {

    const back = () => {
        document.getElementById("botonesEleccion").style.display = "flex";
        document.getElementById("Tabla_Datos_3").style.display = "flex";
        document.getElementById("contentImagenes").style.display = "none";
    }

    /*-------------------------------------------- traer los datos de la compañia --------------------------------------------- */

    const [companies, setCompanies] = useState([]);

    useEffect(() => {
        const fetchCompanies = async () => {
            try {
                const response = await Axios.get(GET_URL_COMPANY);
                if (response) {
                    setCompanies(response.data.compañias);
                }
            } catch (error) {
                console.error('Error en la solicitud:', error);
                Swal.fire({
                    icon: 'error',
                    title: 'Error al cargar las compañías',
                    text: 'Hubo un problema al cargar las compañías. Por favor, inténtalo de nuevo.'
                });
            }
        };
        fetchCompanies();
    }, []);

    /*-------------------------------------------- crear imagen --------------------------------------------- */

    const [idCompania, setIdCompania] = useState('');
    const [url, setUrl] = useState('');
    const [nombreFoto, setNombreFoto] = useState('');

    const handleChangeIdCompania = (e) => {
        setIdCompania(e.target.value);
    };
    const handleChangeUrl = (e) => {
        setUrl(e.target.value);
    };
    const handleChangeNombreFoto = (e) => {
        setNombreFoto(e.target.value);
    };

    const handlePostImagen = async (e) => {
        e.preventDefault();

        try {
            const datos = {
                id_compania: idCompania,
                nombre_foto: nombreFoto,
                ...(url.trim() !== '' && { url: url }), // Agregar URL solo si no está vacía imagen preterminada
            };

            const response = await Axios.post(GENERAL_URL_IMAGE, datos);
            console.log('Respuesta del servidor:', response.data);

            if (response) {
                Swal.fire({
                    icon: 'success',
                    title: 'Haz creado una imagen correctamente',
                    showConfirmButton: false,
                    timer: 1000
                });

                setTimeout(function () {
                    window.location.reload();
                }, 1000);
            }
        } catch (error) {
            console.error('Error:', error);
            Swal.fire({
                title: 'Ups... hubo un problema, revisa bien los parametros',
                icon: 'error',
                toast: true,
                position: 'top',
                showConfirmButton: false,
                width: 560,
                timer: 2800,
                stopKeydownPropagation: true,
                showClass: {
                    popup: 'animate__animated animate__fadeInDown'
                },
                hideClass: {
                    popup: 'animate__animated animate__fadeOutUp'
                },
                customClass: {
                    popup: 'Content_Swall_error',
                }
            });
        }
    };

    return (
        <>
            <form onSubmit={handlePostImagen} className='formConfiguracion'>
                <label htmlFor="id_usuario">Selecciona la compañía que le pertenezca esa imagen:</label>
                <select
                    id="id_compania"
                    name="id_compania"
                    value={idCompania}
                    onChange={handleChangeIdCompania}
                    required
                    className='selectUser'
                >
                    <option value="" disabled>Seleccionar una compañía</option>
                    {companies.map(companies => (
                        <option key={companies.id} value={companies.id}>
                            {companies.nombre}
                        </option>
                    ))}
                </select>
                <label htmlFor="url">URL:</label>
                <input
                    type="text"
                    id="url"
                    name="url"
                    value={url}
                    onChange={handleChangeUrl}
                />
                <label htmlFor="nombre_foto">Nombre de la Foto:</label>
                <input
                    type="text"
                    id="nombre_foto"
                    name="nombre_foto"
                    minLength={6}
                    required
                    value={nombreFoto}
                    onChange={handleChangeNombreFoto}
                />
                <button type="submit">Registrar Imagen</button>
            </form>
            <button className='backForms_1' onClick={back}>Atras</button>
        </>
    )
}