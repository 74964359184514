//url base
export const URL_BASE = process.env.REACT_APP_URL_API;

//urls de mensajes 
export const GET_URL_MESSAGE = "messages";
export const POST_URL_MESSAGE = "messages";
export const PUT_URL_MESSAGE = "messages?id=";
export const DELETE_URL_MESSAGE = "messages/delete?id=";
export const TRIGUER_POST_URL_MESSAGE_ACTIVE = "messages/active?id=";

// urls de compañia
export const GET_URL_COMPANY = "company";

// urls de reportes
export const GET_URL_REPORT = "status";
export const GET_URL_REPORT_SEND = "report";

// urls de vistas
export const GET_URL_VIEWS = "views";
export const POST_URL_VIEWS_DATA = "views/data";
export const POST_URL_VIEWS_TYPE_SEND = "tipoEnvios";

// urls de fecha
export const GET_URL_HOURS = "horasdia";    
export const GET_URL_WEEK = "diasSemana";

// urls de uuario
export const GET_URL_USER = "user";

// urls de plantillas
export const GET_URL_TEMPLATE = "plantillas";
export const DELETE_URL_TEMPLATE = "plantillas/delete";

// url de imagen 
export const GENERAL_URL_IMAGE = "images";
export const PUT_URL_IMAGE = "images/update";

export const GENERAL_URL_DEMO = "demo";

// ------------------------------- urls de pruebas ----------------------------------

export const TRIGUER_POST_URL_USER = "testProgramate";
export const TRIGUER_POST_URL_MESSAGE = "cron/notification";
export const TRIGUER_POST_URL_MESSAGE_CRON = "cron/programate";
