/* -------------------- dependencias -------------------- */
import React, { useEffect, useState } from 'react';
import { GET_URL_COMPANY } from '../../../../../Constancias/Rutas/Rutas';
import Swal from 'sweetalert2';
import Axios from '../../../../../Configuracion/Axios/Axios';
import { botonCerrado } from '../../../../Paginas/Configuracion/Acciones';

/*------------------- componentes --------------- */

/*------------------- rutas --------------- */




/*---------------------------------- logica ---------------------------------------- */

export const ELiminarCompañias = (props) => {

    const back = () => {
        document.getElementById("botonesEleccion").style.display = "flex";
        document.getElementById("Tabla_Datos_2").style.display = "flex";
        document.getElementById("contentCompañiaDelete").style.display = "none";
    }

    /*-------------------------------------------- traer los datos una compañia --------------------------------------------- */
    const [companies, setCompanies] = useState([]);
    const [filterCompañias, setFilterCompañias] = useState([]);
    const [company, setCompany] = useState('');
    const idCompany = parseInt((company), 10);
    console.log(filterCompañias);

    const handleChangeCompanyUp = (event) => {
        const selectedCompanyId = event.target.value;
        setCompany(selectedCompanyId);
    };

    useEffect(() => {
        const fetchCompanies = async () => {
            try {
                const response = await Axios.get(GET_URL_COMPANY);
                if (response.status === 200) {
                    // Obtén todas las compañías
                    const allCompanies = response.data.compañias;

                    // Filtra la compañía con el ID específico
                    const filteredCompany = allCompanies.find(company => company.id === idCompany);
                    // console.log(filteredCompany);
                    // Establece el estado de todas las compañías
                    setCompanies(allCompanies);

                    // Establece el estado de la compañía filtrada
                    setFilterCompañias(filteredCompany ? [filteredCompany] : []);
                }
            } catch (error) {
                console.error('Error en la solicitud:', error);
                Swal.fire({
                    icon: 'error',
                    title: 'Error al cargar las compañías',
                    text: 'Hubo un problema al cargar las compañías. Por favor, inténtalo de nuevo.'
                });
            }
        };
        fetchCompanies();
    }, [idCompany]);

    return (
        <>
            <form className='formConfiguracion'>
                <label htmlFor="id_usuario">Selecciona la compañía que quieres editar:</label>
                <select
                    id="compañiadelete"
                    name="compañiadelete"
                    value={company}
                    onChange={handleChangeCompanyUp}
                    className='selectUser'
                >
                    <option value="" disabled>Seleccionar una compañia</option>
                    {companies.map(companies => (
                        <option key={companies.id} value={companies.id}>
                            {companies.nombre}
                        </option>
                    ))}
                </select>
                <button onClick={botonCerrado}>Eliminar compañia</button>
            </form>

            <button className='backForms' onClick={back}>Atras</button>
        </>
    )
}