/* -------------------- dependencias -------------------- */
// import React, { useState, useEffect } from 'react';
import { useEffect, useState } from 'react';
import '../TusDatos.css';
/*------------------- componentes --------------- */
// import Axios from '../../../Configuracion/Axios/Axios';

/*------------------- Rutas --------------- */
// import {
//     GET_URL_COMPANY,
//     POST_URL_VIEWS_DATA,
//     POST_URL_VIEWS_TYPE_SEND
// } from "../../../Constancias/Rutas/Rutas";

/*------------------- imagenes --------------- */

import { AiOutlineDelete } from "react-icons/ai";
import { IoCreateOutline } from "react-icons/io5";
import { RxUpdate } from "react-icons/rx";
import Axios from '../../../../Configuracion/Axios/Axios';
import { GENERAL_URL_DEMO } from '../../../../Constancias/Rutas/Rutas';
import Swal from 'sweetalert2';

export const CrudRegisters = ({ dontSee, views }) => {
    const [alertHome, setAlertHome] = useState(false); 
    const [register, setRegister] = useState({
        NumeroCelular: '',
        Nivel_1: '',
        Nivel_2: '',
        Nivel_3: '',
        Tipo_Envio: '',
        VLFACTURA: '',
        PTTO: '',
        PEDIDOS: '',
        CUMPLI_PTTO: ''
    });

    const handleChangeRegister = (e) => {
        const { name, value } = e.target;
        setRegister({
            ...register,
            [name]: value
        });
    };

    const [update, setUpdate] = useState({
        NumeroCelular: '',
        Nivel_1: '',
        Nivel_2: '',
        Nivel_3: '',
        // Tipo_Envio: '',
        VLFACTURA: '',
        PTTO: '',
        PEDIDOS: '',
        CUMPLI_PTTO: ''
    });

    const handleChangeRegisteUpdate = (e) => {
        const { name, value } = e.target;
        setUpdate((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        if (register.NumeroCelular === '') {
            alert("Ingrese el número de celular");
            return;
        }

        await Axios.post(GENERAL_URL_DEMO, register)
            .then(() => {
                setAlertHome(true)
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const [datas, setDatas] = useState([])
    const [id, setId] = useState(null)

    const onChainDataId = (event) => {
        const selectedId = event.target.value;
        setId(selectedId);
    };

    console.log(id);

    useEffect(() => {
        const fetchCompanies = async () => {
            try {
                const response = await Axios.get(GENERAL_URL_DEMO)
                if (response) {
                    setDatas(response.data.data);
                }
            } catch (error) {
                console.error('Error en la solicitud en traer los datos:', error);
            }
        };
        fetchCompanies();
    }, []);

    const onSubmitActualizar = async () => {
        await Axios.put(`${GENERAL_URL_DEMO}?id=${id}`, update)
            .then(() => {
                console.log("Se actualizo correctamente")
                setAlertHome(true)
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const onSubmitEliminar = async () => {
        await Axios.delete(`${GENERAL_URL_DEMO}?id=${id}`)
            .then(() => {
                console.log("Se elimino correctamente")
                setAlertHome(true)
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const seeCrear = () => {
        document.getElementById("crearRegistro").style.display = "flex";
        document.getElementById("botonesEleccionData").style.display = "none";
    }

    const seeEliminar = () => {
        document.getElementById("DeleteRegistro").style.display = "flex";
        document.getElementById("botonesEleccionData").style.display = "none";
    }

    const seeActualizar = () => {
        document.getElementById("actuaizarRegistro").style.display = "flex";
        document.getElementById("botonesEleccionData").style.display = "none";
    }

    const back = () => {
        document.getElementById("actuaizarRegistro").style.display = "none";
        document.getElementById("DeleteRegistro").style.display = "none";
        document.getElementById("crearRegistro").style.display = "none";
        document.getElementById("actuaizarRegistro").style.display = "none";
        document.getElementById("botonesEleccionData").style.display = "flex";
        setAlertHome(false);
    }

    useEffect(() => {
        if (alertHome) {
            Swal.fire({
                title: `Se ha realizado correctamente`,
                icon: 'success',
                toast: true,
                position: 'top',
                showConfirmButton: false,
                width: 560,
                timer: 2800,
                stopKeydownPropagation: true,
                showClass: {
                    popup: 'animate__animated animate__fadeInDown'
                },
                hideClass: {
                    popup: 'animate__animated animate__fadeOutUp'
                },
                customClass: {
                    popup: 'Content_Swall_',
                }
            });
            back();
        }
    }, [alertHome]);

    return (
        <>
            {/* <div id='all'> */}
            <div className="datos-container">
                <div className='contentTitle'>
                    <h1>Opciones de datos</h1> <p className="btnCloseVista" onClick={dontSee}>Salir</p>
                </div><hr /><br />

                <div id='botonesEleccionData'>
                    <div className="contentCardConfiguracionOptions" onClick={seeActualizar}>
                        <RxUpdate className='iconConfiguracion' />
                        <h3>Actualizar</h3>
                    </div>
                    <div className="contentCardConfiguracionOptions" onClick={seeCrear}>
                        <IoCreateOutline className='iconConfiguracion' />
                        <h3>Crear</h3>
                    </div>
                    <div className="contentCardConfiguracionOptions" onClick={seeEliminar}>
                        <AiOutlineDelete className='iconConfiguracion' />
                        <h3>Eliminar</h3>
                    </div>
                </div>

                <div id='crearRegistro'>
                    <form onSubmit={onSubmit} className='formConfiguracion'>
                        <label htmlFor="nombre1">Numero de telefono a quien será dirigido:</label>
                        <input
                            type="text"
                            id="NumeroCelular_register"
                            name="NumeroCelular"
                            value={register.NumeroCelular}
                            onChange={handleChangeRegister}
                            required
                            autoComplete="username"
                        />
                        <label htmlFor="Nivel_1">Nivel 1:</label>
                        <input
                            type="text"
                            id="Nivel_1_register"
                            name="Nivel_1"
                            value={register.Nivel_1}
                            onChange={handleChangeRegister}
                        />
                        <label htmlFor="Nivel_2">Nivel 2:</label>
                        <input
                            type="text"
                            id="Nivel_2_register"
                            name="Nivel_2"
                            value={register.Nivel_2}
                            onChange={handleChangeRegister}
                        />
                        <label htmlFor="Nivel_3">Nivel 3:</label>
                        <input
                            type="text"
                            id="Nivel_3_register"
                            name="Nivel_3"
                            value={register.Nivel_3}
                            onChange={handleChangeRegister}
                        />
                        <label htmlFor="Tipo_Envio">Tipo de envio:</label>
                        <select name="Tipo_Envio" className='selectUser' value={register.Tipo_Envio} onChange={handleChangeRegister}>
                            <option value="">Tipo de envio</option>
                            {views.map((view) => (
                                <option key={view.id} value={view.tipo_envio}>
                                    {view.descriptivo}
                                </option>
                            ))}
                        </select>
                        <label htmlFor="PTTO">PTTO:</label>
                        <input
                            type="text"
                            id="PTTO_register"
                            name="PTTO"
                            value={register.PTTO}
                            onChange={handleChangeRegister}
                            required
                        />
                        <label htmlFor="VLFACTURA">VLFACTURA:</label>
                        <input
                            type="text"
                            id="VLFACTURA_register"
                            name="VLFACTURA"
                            value={register.VLFACTURA}
                            onChange={handleChangeRegister}
                            required
                        />
                        <label htmlFor="PEDIDOS">PEDIDOS:</label>
                        <input
                            type="text"
                            id="PEDIDOS_register"
                            name="PEDIDOS"
                            value={register.PEDIDOS}
                            onChange={handleChangeRegister}
                            required
                        />
                        <label htmlFor="CUMPLI_PTTO">CUMPLI_PTTO:</label>
                        <input
                            type="text"
                            id="CUMPLI_PTTO_register"
                            name="CUMPLI_PTTO"
                            value={register.CUMPLI_PTTO}
                            onChange={handleChangeRegister}
                            required
                        />
                        <button type="submit">Registrar datos</button>
                    </form>
                    <button className='backFormsRegister' onClick={back}>Atras</button>
                </div>
                <div id='actuaizarRegistro'>
                    <form onSubmit={onSubmitActualizar} className='formConfiguracion'>
                        <label htmlFor="nombre">Registro que quieres editar:</label>
                        <select className='selectUser' value={id} onChange={onChainDataId}>
                            <option value="">Tipo de envio</option>
                            {datas.map((view) => (
                                <option key={view.id} value={view.idx}>
                                    {view.NumeroCelular}
                                </option>
                            ))}
                        </select>
                        <label htmlFor="NumeroCelular">Numero de telefono:</label>
                        <input
                            type="text"
                            id="NumeroCelular"
                            name="NumeroCelular"
                            value={update.NumeroCelular}
                            onChange={handleChangeRegisteUpdate}
                            required
                            autoComplete="username"
                        />
                        <label htmlFor="Nivel_1">Nivel 1:</label>
                        <input
                            type="text"
                            id="Nivel_1"
                            name="Nivel_1"
                            value={update.Nivel_1}
                            onChange={handleChangeRegisteUpdate}
                        />
                        <label htmlFor="Nivel_2">Nivel 2:</label>
                        <input
                            type="text"
                            id="Nivel_2"
                            name="Nivel_2"
                            value={update.Nivel_2}
                            onChange={handleChangeRegisteUpdate}
                        />
                        <label htmlFor="Nivel_3">Nivel 3:</label>
                        <input
                            type="text"
                            id="Nivel_3"
                            name="Nivel_3"
                            value={update.Nivel_3}
                            onChange={handleChangeRegisteUpdate}
                        />
                        {/* <label htmlFor="id_usuario">Tipo de envio:</label> */}
                        {/* <select name="Tipo_Envio" className='selectUser' value={update.Tipo_Envio} onChange={handleChangeRegister}>
                            <option value="">Tipo de envio</option>
                            {views.map((view) => (
                                <option key={view.id} value={view.id}>
                                    {view.descriptivo}
                                </option>
                            ))}
                        </select> */}
                        <label htmlFor="PTTO">PTTO:</label>
                        <input
                            type="text"
                            id="PTTO"
                            name="PTTO"
                            value={update.PTTO}
                            onChange={handleChangeRegisteUpdate}
                        />
                        <label htmlFor="VLFACTURA">VLFACTURA:</label>
                        <input
                            type="text"
                            id="VLFACTURA"
                            name="VLFACTURA"
                            value={update.VLFACTURA}
                            onChange={handleChangeRegisteUpdate}
                        />
                        <label htmlFor="PEDIDOS">PEDIDOS:</label>
                        <input
                            type="text"
                            id="PEDIDOS"
                            name="PEDIDOS"
                            value={update.PEDIDOS}
                            onChange={handleChangeRegisteUpdate}
                        />
                        <label htmlFor="CUMPLI_PTTO">CUMPLI_PTTO:</label>
                        <input
                            type="text"
                            id="CUMPLI_PTTO"
                            name="CUMPLI_PTTO"
                            value={update.CUMPLI_PTTO}
                            onChange={handleChangeRegisteUpdate}
                        />
                        <button type="submit">Actualizar</button>
                    </form>
                    <button className='backFormsRegister' onClick={back}>Atras</button>
                </div>
                <div id='DeleteRegistro'>
                    <form onSubmit={onSubmitEliminar} className='formConfiguracion'>
                        <label htmlFor="nombre">Registro que quieres editar:</label>
                        <select className='selectUser' value={id} onChange={onChainDataId}>
                            <option value="">Tipo de envio</option>
                            {datas.map((view) => (
                                <option key={view.id} value={view.idx}>
                                    {view.NumeroCelular}
                                </option>
                            ))}
                        </select>
                        <button type="submit">Eliminar</button>
                    </form>
                    <button className='backFormsRegister' onClick={back}>Atras</button>
                </div>
            </div>
        </>
    )
}