/* -------------------- dependencias -------------------- */
import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';

/*------------------- componentes --------------- */
import Axios from '../../../../../Configuracion/Axios/Axios';

/*------------------- rutas --------------- */
import { GET_URL_USER } from '../../../../../Constancias/Rutas/Rutas';


/*---------------------------------- logica ---------------------------------------- */

export const ActualizarUsuario = (props) => {

    const back = () => {
        document.getElementById("botonesEleccion").style.display = "flex";
        document.getElementById("updateUsuario").style.display = "none";
        document.getElementById("Tabla_Datos").style.display = "flex";
    }

    /*-------------------------------------------- traer todos los usuarios --------------------------------------------- */
    const [usuarios, setUsuarios] = useState([]);
    const [filtroUsuarios, setFiltroUsuarios] = useState([]);
    const [idUser, setIdUser] = useState('');
    const idUsers = parseInt((idUser), 10);
    // console.log(idUsers);

    const handleChangeUserUp = (event) => {
        const selecteduseryId = event.target.value;
        setIdUser(selecteduseryId);
    };

    useEffect(() => {
        const fetchUsuarios = async () => {
            try {
                const response = await Axios.get(GET_URL_USER);

                if (response.status === 200) {
                    setUsuarios(response.data.users);

                    const usuariosFiltrados = idUser
                        ? response.data.users.filter(user => user.id === parseInt(idUser, 10))
                        : response.data.users;

                    setFiltroUsuarios(usuariosFiltrados);
                }
            } catch (error) {
                console.error('Error en la solicitud:', error.message);
            }
        };

        fetchUsuarios();
    }, [idUser]);

    /*-------------------------------------------- actualizar usuario --------------------------------------------- */


    const [datosUpdate, setDatosUpdate] = useState({
        correo: '',
        contrasena: '',
        nombre: '',
        rol: '',
        countDemo: ''
    });

    const handleChangeUp = (e) => {
        setDatosUpdate({
            ...datosUpdate,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmitUp = async (e) => {
        e.preventDefault();
        try {
            const response = await Axios.put(`${GET_URL_USER}?id=${idUsers}`, datosUpdate);
            console.log('Respuesta del servidor:', response.data);
            if (response) {
                Swal.fire({
                    icon: "success",
                    title: "Haz actualizado un usuario correctamente",
                    showConfirmButton: false,
                    timer: 1500
                });

                setTimeout(function () {
                    window.location.reload();
                }, 1000);
            }
        } catch (error) {
            if (error.response) {
                // El servidor respondió con un código de estado fuera del rango 2xx
                console.error('Respuesta del servidor con error:', error.response.data);
                Swal.fire({
                    title: `Ups... hubo un problema, revisa bien los parametros`,
                    icon: 'error',
                    toast: true,
                    position: 'top',
                    showConfirmButton: false,
                    width: 560,
                    timer: 2800,
                    stopKeydownPropagation: true,
                    showClass: {
                        popup: 'animate__animated animate__fadeInDown'
                    },
                    hideClass: {
                        popup: 'animate__animated animate__fadeOutUp'
                    },
                    customClass: {
                        popup: 'Content_Swall_error',
                    }
                });
            } else if (error.request) {
                // La solicitud fue realizada pero no se recibió respuesta
                console.error('No se recibió respuesta del servidor:', error.request);
            } else {
                // Ocurrió un error antes de enviar la solicitud
                console.error('Error en la solicitud:', error.message);
            }
        }
    };

    return (
        <>
            <form onSubmit={handleSubmitUp} className='formConfiguracion'>
                <label htmlFor="id_usuario">Escoge el usuario que quieras editar:</label>
                <select
                    id="id_usuarioup"
                    name="id_usuarioup"
                    value={idUser}
                    onChange={handleChangeUserUp}
                    required
                    className='selectUser'
                >
                    <option value="" disabled>Seleccionar usuario</option>
                    {usuarios.map(usuario => (
                        <option key={usuario.id} value={usuario.id}>
                            {usuario.nombre}
                        </option>
                    ))}
                </select>
                <label htmlFor="correo">Correo:</label>
                <input
                    type="email"
                    id="correo2"
                    name="correo"
                    placeholder={filtroUsuarios.length > 0 ? filtroUsuarios[0].correo : ''}
                    autoComplete="username"
                    value={datosUpdate.correo}
                    onChange={handleChangeUp}
                />
                <label htmlFor="contrasena">Contraseña:</label>
                <input
                    type="password"
                    id="contrasena2"
                    name="contrasena"
                    placeholder={filtroUsuarios.length > 0 ? filtroUsuarios[0].contrasena : ''}
                    autoComplete="current-password"
                    value={datosUpdate.contrasena}
                    onChange={handleChangeUp}
                />
                <label htmlFor="nombre">Nombre:</label>
                <input
                    type="text"
                    id="nombre3"
                    name="nombre"
                    minLength={8}
                    placeholder={filtroUsuarios.length > 0 ? filtroUsuarios[0].nombre : ''}
                    value={datosUpdate.nombre}
                    onChange={handleChangeUp}
                />
                <label htmlFor="rol">Notificaciones disponibles:</label>
                <input
                    type="text"
                    id="countDemo"
                    name="countDemo"
                    minLength={2}
                    placeholder={filtroUsuarios.length > 0 ? filtroUsuarios[0].countDemo : ''}
                    value={datosUpdate.countDemo}
                    onChange={handleChangeUp}
                />
                <label htmlFor="rol">Rol:</label>
                <select
                    id="rol2"
                    name="rol"
                    value={datosUpdate.rol}
                    onChange={handleChangeUp}
                    className='selectUser'
                >
                    <option value="" style={{ color: '#00a878' }}>Rol de usuario: ({filtroUsuarios.length > 0 ? filtroUsuarios[0].rol : ''})</option>
                    <option value="superAdmin" style={{ color: '#00a878' }}>SuperAdmin</option>
                    {/* <option value="piloto" style={{ color: '#00a878' }}>Piloto</option> */}
                    <option value="admin" style={{ color: '#00a878' }}>Piloto</option>
                </select>
                <button type="submit">Acualizar usuario</button>
            </form>
            <button className='backForms_' onClick={back}>Atras</button>
        </>
    )
}