/* -------------------- dependencias -------------------- */
import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
/*------------------- componentes --------------- */
import Axios from '../../../../../Configuracion/Axios/Axios';

/*------------------- rutas --------------- */
import { POST_URL_VIEWS_TYPE_SEND } from '../../../../../Constancias/Rutas/Rutas';


export const EliminarTiposEnvio = (props) => {

    const back = () => {
        document.getElementById("botonesEleccionComercial").style.display = "flex";
        document.getElementById("contentEliminarTipos").style.display = "none";
        document.getElementById("Tabla_Datos").style.display = "flex";
    }

    /*------------------------------------------- eliminar tipo de envio  /*------------------------------------------- */

    const [tiposEnvio, setTiposEnvio] = useState([]);
    const [selectedTipoEnvio, setSelectedTipoEnvio] = useState('');

    const handleTipoEnvioChange = (e) => {
        setSelectedTipoEnvio(e.target.value);
    };

    useEffect(() => {
        // Obtener la lista de tipos de envío
        const obtenerTiposEnvio = async () => {
            try {
                const response = await Axios.get(POST_URL_VIEWS_TYPE_SEND);
                // Verificar que response.data sea un array antes de asignarlo
                if (Array.isArray(response.data.tiposEnvio)) {
                    setTiposEnvio(response.data.tiposEnvio);
                } else {
                    console.error('La respuesta no es un array:', response.data);
                }
            } catch (error) {
                console.error('Error al obtener tipos de envío:', error.message);
            }
        };

        obtenerTiposEnvio();
    }, []);

    const handleEliminarEnvio = async (e) => {
        e.preventDefault();
        try {
            // Realizar la solicitud de eliminación con Axios
            const response = await Axios.delete(POST_URL_VIEWS_TYPE_SEND, {
                data: { descriptivo: selectedTipoEnvio },
            });

            if (response) {
                Swal.fire({
                    icon: "success",
                    title: "Haz eliminado este tipo de envio correctamente",
                    showConfirmButton: false,
                    timer: 1500
                });

                setTimeout(function () {
                    window.location.reload();
                }, 1000);
            }
            // Manejar la respuesta según sea necesario
            console.log('Respuesta del servidor:', response.data);
        } catch (error) {
            // Manejar errores de la solicitud
            console.error('Error al enviar la solicitud de eliminación:', error.message);
            Swal.fire({
                title: `Ups... hubo un problema, revisa bien los parametros`,
                icon: 'error',
                toast: true,
                position: 'top',
                showConfirmButton: false,
                width: 560,
                timer: 2800,
                stopKeydownPropagation: true,
                showClass: {
                    popup: 'animate__animated animate__fadeInDown'
                },
                hideClass: {
                    popup: 'animate__animated animate__fadeOutUp'
                },
                customClass: {
                    popup: 'Content_Swall_error',
                }
            });
        }
    };

    return (
        <>
            <form onSubmit={handleEliminarEnvio} className='formConfiguracion'>
                <label>
                    Seleccionar Tipo de Envío a Eliminar:
                    <select
                        value={selectedTipoEnvio}
                        onChange={handleTipoEnvioChange}
                        className='selectUser'
                        required
                    >
                        <option value="" disabled>Seleccione un tipo de envío</option>
                        {tiposEnvio.map((tipo) => (
                            <option key={tipo.descriptivo} value={tipo.descriptivo}>
                                {tipo.descriptivo}
                            </option>
                        ))}
                    </select>
                </label>
                <button type="submit">Eliminar Tipo de Envío</button>
            </form>
            <button className='backForms_1' onClick={back}>Atras</button>
        </>
    )
}