/* -------------------- dependencias -------------------- */
import React, { useState, useEffect, useRef } from 'react';
import Select from 'react-select';
import '../Notificaciones.css'
import Swal from 'sweetalert2';
// import cronstrue from 'cronstrue';
/*------------------- componentes --------------- */
import Axios from '../../../../Configuracion/Axios/Axios';
import { Loading } from '../../../Ui/Loading/Loading'
/*------------------- rutas --------------- */
import {
    PUT_URL_MESSAGE,
    GET_URL_HOURS,
    GET_URL_WEEK,
} from "../../../../Constancias/Rutas/Rutas";




/*---------------------------------- logica ---------------------------------------- */

export const ActualizarCalen = ({ nombre, datosOne, setRefresh }) => {


    /*------------------------------------------------ traer codigo cron dias y horas -------------------------------------------------*/

    const [diasDeLaSemana, setDiasDeLaSemana] = useState([]);
    const [horasDelDia, setHorasDelDia] = useState([]);
    const [diasSeleccionados, setDiasSeleccionados] = useState([]);
    const [horasSeleccionadas, setHorasSeleccionadas] = useState([]);
    const [loading, setLoading] = useState(false);
    const seleccionHoras = useRef(null);
    const seleccionSemana = useRef(null);

    useEffect(() => {
        const obtenerDiasDeLaSemana = async () => {
            try {
                const response = await Axios.get(GET_URL_WEEK);
                setDiasDeLaSemana(response.data.table.map(dia => ({ value: dia.id, label: dia.dias })));
            } catch (error) {
                console.error('Error al obtener días de la semana desde la API:', error);
            }
        };

        const obtenerHorasDelDia = async () => {
            try {
                const response = await Axios.get(GET_URL_HOURS);
                setHorasDelDia(response.data.table.map(hora => ({ value: hora.cod, label: hora.horas })));
            } catch (error) {
                console.error('Error al obtener horas del día desde la API:', error);
            }
        };
        obtenerDiasDeLaSemana();
        obtenerHorasDelDia();
    }, []);

    /*---------------------------------- construyo el calendario con codigo cron  ---------------------------------------- */

    const handleEnviarCron = () => {
        /*---------------------------------- validacion de campos vacios  ---------------------------------------- */
        if (!diasSeleccionados || diasSeleccionados.length === 0 || !horasSeleccionadas || horasSeleccionadas.length === 0) {
            if (seleccionSemana.current) {
                Swal.fire({
                    title: `Selecciona los días y horas para continuar`,
                    icon: 'info',
                    toast: true,
                    position: 'top',
                    showConfirmButton: false,
                    width: 560,
                    timer: 2800,
                    stopKeydownPropagation: true,
                    showClass: {
                        popup: 'animate__animated animate__fadeInDown'
                    },
                    hideClass: {
                        popup: 'animate__animated animate__fadeOutUp'
                    },
                    customClass: {
                        popup: 'Content_Swall_error',
                    }
                });
            }
            return;
        }
        const diasSeleccionadosStr = diasSeleccionados.map(dias => dias.value).join(',');
        const horasSeleccionadasStr = horasSeleccionadas.map(horas => horas.value).join(',');
        const cronExpresion = `0 ${horasSeleccionadasStr} * * ${diasSeleccionadosStr}`;
        // const cronExpresionLegible = cronstrue.toString(cronExpresion);

        // Aquí podrías enviar la expresión cron a tu API
        localStorage.setItem("cronGenerate", cronExpresion)
        // console.log('Expresión cron generada:', cronExpresion);
        // console.log('Expresión cron legible:', cronExpresionLegible);
        handleActualizarNotificacion();
    };

    /*-------------------------------------------- actualizar Calendario --------------------------------------------- */

    const handleActualizarNotificacion = async (e) => {
        const diasSeleccionadosStr2 = diasSeleccionados.map(dias => dias.label).join(', ');
        const horasSeleccionadasStr2 = horasSeleccionadas.map(horas => horas.label).join(', ');
        const calendario = "Esta notificación ejecutará. " + diasSeleccionadosStr2 + " a las " + horasSeleccionadasStr2;

        const id = localStorage.getItem("codigoGenerate")
        const dataToUpdate = {
            codigo_cron: localStorage.getItem("cronGenerate"),
            cron_active: true,
            calendario: calendario
        };

        try {
            setLoading(true)
            // const datas = {
            //     id: id
            // };
            // const responseOff = await Axios.post(TRIGUER_POST_URL_MESSAGE_CRON, datas);
            // const responseData2 = responseOff.data;
            // console.log(responseData2);

            // if (responseOff) {
                // const queryParams = new URLSearchParams(dataToUpdate); // Convertir los datos a parámetros de consulta
                const response = await Axios.put(`${PUT_URL_MESSAGE}${id}`, dataToUpdate);
                const data = response.data;

                setTimeout(function () {
                    setRefresh(false);
                    localStorage.removeItem('codigoGenerate');
                    localStorage.removeItem('cronGenerate');
                    setDiasSeleccionados([]);
                    setHorasSeleccionadas([]);
                    Swal.fire({
                        icon: 'success',
                        title: 'Se ha actualizado la notificación con un calendario',
                        toast: true,
                        position: 'top',
                        showConfirmButton: false,
                        width: 560,
                        timer: 2800,
                        stopKeydownPropagation: true,
                        showClass: {
                            popup: 'animate__animated animate__fadeInDown'
                        },
                        hideClass: {
                            popup: 'animate__animated animate__fadeOutUp'
                        },
                        customClass: {
                            popup: 'Content_Swall_succes_popap',
                        }
                    });
                    document.getElementById("allCalendario_").style.display = "none";
                    setLoading(false);
                }, 1000);
                return data;
            // }
        } catch (error) {
            console.error('Error en la solicitud: ', error);
            let err = error.response.status
            let msg = error.response.data.err
            Swal.fire({
                icon: 'error',
                title: 'No podemos actualizar. Inténtalo de nuevo más tarde',
                text: `(Tu notificacion está protegida). No.error: ${err} mensaje: ${msg}`,
                customClass: {
                    container: 'Content_Swal_All'
                }
            });
            setLoading(false);
        }
    };

    const handleEnviarClick2 = () => {
        // window.location.reload();
        setDiasSeleccionados([]);
        setHorasSeleccionadas([]);
        document.getElementById("allCalendario_").style.display = "none";
    };

    /*---------------------------------- borrar el calendario  ---------------------------------------- */

    const handleBorrarCalendarioNotificacion = async (e) => {
        const id = parseInt(localStorage.getItem("codigoGenerate"), 10);
        const dataToUpdate = {
            codigo_cron: " ",
            cron_active: false,
            calendario: " "
        };

        try {
            setLoading(true)
            // const datas = {
            //     id: id
            // };
            // const responseOff = await Axios.post(TRIGUER_POST_URL_MESSAGE_CRON, datas);
            // const responseData2 = responseOff.data;
            // console.log(responseData2);

            // valida que el cron se desactive
            // if (responseOff) {
                const response = await Axios.put(`${PUT_URL_MESSAGE}${id}`, dataToUpdate);
                const data = response.data;

                setTimeout(function () {
                    console.log('Datos actualizados:', data);
                    setRefresh(false);
                    localStorage.removeItem('codigoGenerate');
                    localStorage.removeItem('cronGenerate');
                    setDiasSeleccionados([]);
                    setHorasSeleccionadas([]);
                    document.getElementById("allCalendario_").style.display = "none";
                    Swal.fire({
                        icon: 'success',
                        title: 'Se ha eliminado el calendario exitosamente',
                        toast: true,
                        position: 'top',
                        showConfirmButton: false,
                        width: 560,
                        timer: 2800,
                        stopKeydownPropagation: true,
                        showClass: {
                            popup: 'animate__animated animate__fadeInDown'
                        },
                        hideClass: {
                            popup: 'animate__animated animate__fadeOutUp'
                        },
                        customClass: {
                            popup: 'Content_Swall_succes_popap',
                        }
                    });
                    setLoading(false);
                }, 1000);
                return data;

            // } else {
            //     Swal.fire({
            //         title: `Ups... hubo un problema al actualizar el calendario, intenta mas tarde`,
            //         icon: 'error',
            //         toast: true,
            //         position: 'top',
            //         showConfirmButton: false,
            //         width: 560,
            //         timer: 2800,
            //         stopKeydownPropagation: true,
            //         showClass: {
            //             popup: 'animate__animated animate__fadeInDown'
            //         },
            //         hideClass: {
            //             popup: 'animate__animated animate__fadeOutUp'
            //         },
            //         customClass: {
            //             popup: 'Content_Swall_error',
            //         }
            //     });
            // }
        } catch (error) {
            console.error('Error en la solicitud: ', error);
            let err = error.response.status
            let msg = error.response.data.err
            Swal.fire({
                icon: 'error',
                title: 'No podemos actualizar. Inténtalo de nuevo más tarde',
                text: `(Tu notificacion está protegida). No.error: ${err} mensaje: ${msg}`,
                customClass: {
                    container: 'Content_Swal_All'
                }
            });
            setLoading(false);
        }
    };

    return (
        <>
            {!loading ?
                <div id='mainCalendario_'>
                    <div className='contentTextDatos'>
                        {nombre === 'nothing' ? <h2>No tienes un calendario</h2> : <h2>{nombre}</h2>} <br />
                    </div>

                    <div className="content-calendario">
                        <h2>Agrega días de la semana</h2>
                        <Select
                            options={diasDeLaSemana}
                            isMulti
                            className='selectCalendario'
                            placeholder="Días"
                            value={diasSeleccionados}
                            ref={seleccionHoras}
                            onChange={setDiasSeleccionados}
                        />
                        <h2>Agrega horas del día</h2>
                        <Select
                            options={horasDelDia}
                            isMulti
                            className='selectCalendario'
                            placeholder="Horas"
                            value={horasSeleccionadas}
                            ref={seleccionSemana}
                            onChange={setHorasSeleccionadas}
                        />
                        <div className='contenct-btn-calendaraio'>
                            <button className='btn1' onClick={handleEnviarCron}>{nombre === 'nothing' ? "Agregar calendario" : "Nuevo calendario"}</button>
                            {nombre === 'nothing' ? null : <button className='btn1' onClick={handleBorrarCalendarioNotificacion}>Borrar calendario</button>}
                            <button className='btn1' onClick={handleEnviarClick2}>Salir</button>
                        </div>
                    </div>
                </div>
                : <div className='mainCalendario_2'>
                    <Loading />
                </div>}
        </>
    )
}