/* -------------------- dependencias -------------------- */
import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';

/*------------------- componentes --------------- */
import Axios from '../../../../../Configuracion/Axios/Axios';

/*------------------- rutas --------------- */
import { GET_URL_COMPANY, GET_URL_USER } from '../../../../../Constancias/Rutas/Rutas';


export const ActuaizarCompañia = (props) => {

    const back = () => {
        document.getElementById("botonesEleccion").style.display = "flex";
        document.getElementById("actuaizarCompañia").style.display = "none";
        document.getElementById("Tabla_Datos_2").style.display = "flex";
    }

    /*-------------------------------------------- traer todos los usuarios --------------------------------------------- */
    const [usuarios, setUsuarios] = useState([]);

    useEffect(() => {
        const fetchUsuarios = async () => {
            try {
                const response = await Axios.get(GET_URL_USER);
                setUsuarios(response.data.users);
            } catch (error) {
                console.error('Error al obtener la lista de usuarios:', error);
            }
        };

        fetchUsuarios();
    }, []);

    /*-------------------------------------------- traer los datos una compañia --------------------------------------------- */
    const [companies, setCompanies] = useState([]);
    const [filterCompañias, setFilterCompañias] = useState([]);
    const [company, setCompany] = useState('');
    const idCompany = parseInt((company), 10);
    // console.log(idCompany);

    const handleChangeCompanyUp = (event) => {
        const selectedCompanyId = event.target.value;
        setCompany(selectedCompanyId);
    };

    useEffect(() => {
        const fetchCompanies = async () => {
            try {
                const response = await Axios.get(GET_URL_COMPANY);
                if (response.status === 200) {
                    // Obtén todas las compañías
                    const allCompanies = response.data.compañias;

                    // Filtra la compañía con el ID específico
                    const filteredCompany = allCompanies.find(company => company.id === idCompany);
                    // console.log(filteredCompany);
                    // Establece el estado de todas las compañías
                    setCompanies(allCompanies);

                    // Establece el estado de la compañía filtrada
                    setFilterCompañias(filteredCompany ? [filteredCompany] : []);
                }
            } catch (error) {
                console.error('Error en la solicitud:', error);
                Swal.fire({
                    icon: 'error',
                    title: 'Error al cargar las compañías',
                    text: 'Hubo un problema al cargar las compañías. Por favor, inténtalo de nuevo.'
                });
            }
        };
        fetchCompanies();
    }, [idCompany]);

    /*-------------------------------------------- Atualizar compañia --------------------------------------------- */

    const [companyDataUpdate, setCompanyDataUpdate] = useState({
        id_usuario: '',
        nombre: '',
        host: '',
        bd: '',
        token: '',
        api: '',
        wh_account: '',
        wh_number: ''
    });

    const handleChangeCompanyUpdate = (event) => {
        const { name, value } = event.target;
        setCompanyDataUpdate((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleSubmitCompanyUpdate = async (e) => {
        e.preventDefault();
        try {
            const response = await Axios.put(`${GET_URL_COMPANY}?id=${idCompany}`, companyDataUpdate);
            console.log('Respuesta del servidor:', response.data);
            if (response) {
                Swal.fire({
                    icon: "success",
                    title: "Haz actualizado la compañia correctamente",
                    showConfirmButton: false,
                    timer: 1500
                });

                setTimeout(function () {
                    setCompany();
                    window.location.reload();
                }, 1000);
            }
            setCompanyDataUpdate('');
        } catch (error) {
            console.error('Error en la solicitud:', error);
            Swal.fire({
                title: `Ups... hubo un problema, revisa bien los parametros`,
                icon: 'error',
                toast: true,
                position: 'top',
                showConfirmButton: false,
                width: 560,
                timer: 2800,
                stopKeydownPropagation: true,
                showClass: {
                    popup: 'animate__animated animate__fadeInDown'
                },
                hideClass: {
                    popup: 'animate__animated animate__fadeOutUp'
                },
                customClass: {
                    popup: 'Content_Swall_error',
                }
            });
        }
    };

    return (
        <>
            <form onSubmit={handleSubmitCompanyUpdate} className='formConfiguracion'>
                <label htmlFor="compañia2">Selecciona la compañía que quieres editar:</label>
                <select
                    id="compañia2"
                    name="compañia2"
                    value={company}
                    onChange={handleChangeCompanyUp}
                    required
                    className='selectUser'
                >
                    <option value="" disabled>Seleccionar una compañia</option>
                    {companies.map(companies => (
                        <option key={companies.id} value={companies.id}>
                            {companies.nombre}
                        </option>
                    ))}
                </select>
                <label htmlFor="nombre2">Nombre de la compañía:</label>
                <input
                    type="text"
                    id="nombre2"
                    name="nombre"
                    placeholder={filterCompañias.length > 0 ? filterCompañias[0].nombre : ''}
                    value={companyDataUpdate.nombre}
                    onChange={handleChangeCompanyUpdate}
                    autoComplete="username"
                />
                <label htmlFor="host2">Host:</label>
                <input
                    type="text"
                    id="host2"
                    name="host"
                    placeholder={filterCompañias.length > 0 ? filterCompañias[0].host : ''}
                    value={companyDataUpdate.host}
                    onChange={handleChangeCompanyUpdate}
                />
                <label htmlFor="bd2">Base de datos:</label>
                <input
                    type="text"
                    id="bd2"
                    name="bd"
                    placeholder={filterCompañias.length > 0 ? filterCompañias[0].bd : ''}
                    value={companyDataUpdate.bd}
                    onChange={handleChangeCompanyUpdate}
                />
                <label htmlFor="id_usuario2">Usuario al que pertenece esa compañía:</label>
                <select
                    id="id_usuario2"
                    name="id_usuario"
                    value={companyDataUpdate.id_usuario}
                    onChange={handleChangeCompanyUpdate}
                    className='selectUser'
                >
                    <option value="" >Usuario: ({filterCompañias.length > 0 ? filterCompañias[0].id_usuario : ''})</option>
                    {usuarios.map(usuario => (
                        <option key={usuario.id} value={usuario.id}>
                            {usuario.nombre}
                        </option>
                    ))}
                </select>
                <label htmlFor="token2">Token:</label>
                <input
                    type="text"
                    id="token2"
                    name="token"
                    placeholder={filterCompañias.length > 0 ? filterCompañias[0].token : ''}
                    value={companyDataUpdate.token}
                    onChange={handleChangeCompanyUpdate}
                />
                <label htmlFor="api2">API:</label>
                <input
                    type="text"
                    id="api2"
                    name="api"
                    placeholder={filterCompañias.length > 0 ? filterCompañias[0].api : ''}
                    value={companyDataUpdate.api}
                    onChange={handleChangeCompanyUpdate}
                />
                <label htmlFor="number2">Numero de id de la compañia (wh_number):</label>
                <input
                    type="text"
                    id="number2"
                    name="wh_number"
                    value={companyDataUpdate.wh_number}
                    placeholder={filterCompañias.length > 0 ? filterCompañias[0].wh_number : ''}
                    onChange={handleChangeCompanyUpdate}
                />
                <label htmlFor="wh_account">Numero de id de la cuenta business (wh_account):</label>
                <input
                    type="text"
                    id="wh_account"
                    name="wh_account"
                    value={companyDataUpdate.wh_account}
                    placeholder={filterCompañias.length > 0 ? filterCompañias[0].wh_account : ''}
                    onChange={handleChangeCompanyUpdate}
                />
                <button type="submit">Actualizar compañía</button>
            </form>
            <button className='backForms' onClick={back}>Atras</button>
        </>
    )
}