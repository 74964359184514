/*-------------------------------------------- acciones de ver y no ver --------------------------------------------- */
import Swal from "sweetalert2";

export const dontSee = () => {
    document.getElementById("contentDias").style.display = "none";
    document.getElementById("contentUser").style.display = "none";
    document.getElementById("contentCompany").style.display = "none";
    document.getElementById("contentPlantillas").style.display = "none";

    document.getElementById("all").style.display = "none";
    document.getElementById("Tabla_Datos_3").style.display = "none";
    document.getElementById("botonesEleccion").style.display = "none";

    document.getElementById("crearCompañia").style.display = "none";
    document.getElementById("createUsuario").style.display = "none";
    document.getElementById("contentImagenes").style.display = "none";
    document.getElementById("CrearPlantillas").style.display = "none";

    document.getElementById("contentImagenesUpdate").style.display = "none";
    document.getElementById("actuaizarCompañia").style.display = "none";
    document.getElementById("updateUsuario").style.display = "none";

    document.getElementById("EliminarPlatillas").style.display = "none";
    document.getElementById("contentImagenesDelete").style.display = "none";
    document.getElementById("contentCompañiaDelete").style.display = "none";
    document.getElementById("contentUsuarioDelete").style.display = "none";

}
export const seeImagenes = () => {
    document.getElementById("contentDias").style.display = "flex";
    document.getElementById("all").style.display = "flex";
    document.getElementById("Tabla_Datos_3").style.display = "flex";
    document.getElementById("botonesEleccion").style.display = "flex";
}
export const seeUser = () => {
    document.getElementById("contentUser").style.display = "flex";
    document.getElementById("all").style.display = "flex";
    document.getElementById("botonesEleccion").style.display = "flex";
    document.getElementById("Tabla_Datos").style.display = "flex";
}
export const seeCompany = () => {
    document.getElementById("contentCompany").style.display = "flex";
    document.getElementById("botonesEleccion").style.display = "flex";
    document.getElementById("Tabla_Datos_2").style.display = "flex";
    document.getElementById("all").style.display = "flex";
}
export const seePlantilla = () => {
    document.getElementById("contentPlantillas").style.display = "flex";
    document.getElementById("all").style.display = "flex";
}
// ------------------ crear ------------------------
export const crearCompañia = () => {
    document.getElementById("crearCompañia").style.display = "flex";
    document.getElementById("botonesEleccion").style.display = "none";
    document.getElementById("Tabla_Datos_2").style.display = "none";
}
export const crearUsuario = () => {
    document.getElementById("createUsuario").style.display = "flex";
    document.getElementById("botonesEleccion").style.display = "none";
    document.getElementById("Tabla_Datos").style.display = "none";
}
export const crearImage = () => {
    document.getElementById("contentImagenes").style.display = "flex";
    document.getElementById("Tabla_Datos_3").style.display = "none";
    document.getElementById("botonesEleccion").style.display = "none";
}
export const CrearPlantillas = () => {
    document.getElementById("CrearPlantillas").style.display = "flex";
    document.getElementById("botonesEleccion").style.display = "none";
}
// ------------------ actualizar ------------------------

export const updateImage = () => {
    document.getElementById("contentImagenesUpdate").style.display = "flex";
    document.getElementById("Tabla_Datos_3").style.display = "none";
    document.getElementById("botonesEleccion").style.display = "none";
}
export const updateUsuario = () => {
    document.getElementById("updateUsuario").style.display = "flex";
    document.getElementById("botonesEleccion").style.display = "none";
    document.getElementById("Tabla_Datos").style.display = "none";
}
export const updateCompañia = () => {
    document.getElementById("actuaizarCompañia").style.display = "flex";
    document.getElementById("botonesEleccion").style.display = "none";
    document.getElementById("Tabla_Datos_2").style.display = "none";
}
// ------------------ eliminar ------------------------
export const EliminarPlatillas = () => {
    document.getElementById("EliminarPlatillas").style.display = "flex";
    document.getElementById("botonesEleccion").style.display = "none";
}
export const EliminarImagenes = () => {
    document.getElementById("Tabla_Datos_3").style.display = "none";
    document.getElementById("botonesEleccion").style.display = "none";
    document.getElementById("contentImagenesDelete").style.display = "flex";
}
export const EliminarCompañia = () => {
    document.getElementById("botonesEleccion").style.display = "none";
    document.getElementById("contentCompañiaDelete").style.display = "flex";
    document.getElementById("Tabla_Datos_2").style.display = "none";
}
export const EliminarUsuarios = () => {
    document.getElementById("botonesEleccion").style.display = "none";
    document.getElementById("contentUsuarioDelete").style.display = "flex";
    document.getElementById("Tabla_Datos").style.display = "none";
}
// ------------------ cerrado ------------------------
export const botonCerrado = () => {
    Swal.fire("Ups...  este boton no esta disponible en este momento");
}
