import React, { useState, useEffect } from 'react';
import './Navbar.css';
import logo from '../../../ImagenesInterfaz/Logo/logo_Nav.gif'
import { Link } from 'react-router-dom';
import { IoExitOutline } from "react-icons/io5";
import { LiaUserCircleSolid } from "react-icons/lia";
import { GET_URL_USER } from '../../../Constancias/Rutas/Rutas';
import Axios from '../../../Configuracion/Axios/Axios';

const Navbar = () => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    const openNav = () => {
        document.getElementById("mySidenav").style.width = "300px";
        document.getElementById("main").style.marginLeft = "300px";
        setIsSidebarOpen(true);
    };

    const closeNav = () => {
        document.getElementById("mySidenav").style.width = "0";
        document.getElementById("main").style.marginLeft = "0";
        setIsSidebarOpen(false);
    };

    const [user, setUser] = useState('');
    const [id, setId] = useState('');
    const [rolUsuario, setRol] = useState('');
    const esSuperAdmin = () => rolUsuario === 'superAdmin';
    const esAdmin = () => rolUsuario === 'admin';

    useEffect(() => {
        const obtenerUsuarioDesdeLocalStorage = () => {
            const storedObjectString = localStorage.getItem('userObject');
            if (storedObjectString) {
                const userObject = JSON.parse(storedObjectString);
                setUser(userObject.nombre);
                setId(userObject.auth)
                setRol(userObject.account_plan_user_role)
            } else {
                console.log('No se encontró el objeto en el localStorage');
            }
        };
        obtenerUsuarioDesdeLocalStorage();
    }, []);

    const isAuthenticatedDispach = async () => {
        let idx = id
        let active = true
        const data = {
            season_active: !active
        }
        const response = await Axios.put(`${GET_URL_USER}?id=${idx}`, data);
        if (response) {
            localStorage.clear()
            window.location.href = '/login';
        };
    };

    return (
        <>
            <div className="sidebar">
                <div id="mySidenav" className={`sidenav ${isSidebarOpen ? 'open' : ''}`}>
                    <p className="closebtn" onClick={closeNav}>&times;</p>
                    <div>
                        {esSuperAdmin() && (
                            <>
                                <Link to="/Dashboard">Panel de control</Link>
                                <Link to="/inicio">Notificaciones</Link>
                                <Link to="/datos">Datos</Link>
                                <Link to={`/configuracion/auth/${id}/page/${rolUsuario}`} >Configuración</Link>
                                <Link to={`/estructuraComercial/auth/${id}/page/${rolUsuario}`} >Estructura comercial</Link>
                                <Link to="/ayuda" >Ayuda</Link>
                            </>
                        )}

                        {esAdmin() && (
                            <>
                                <Link to="/Dashboard">Panel de control</Link>
                                <Link to="/inicio">Notificaciones</Link>
                                <Link to="/datos">Datos</Link>
                                <Link to="/ayuda" >Ayuda</Link>
                            </>
                        )}
                    </div>
                </div>
                <div id="main">
                    <div className='menu'>
                        <img src={logo} className='logoNav' alt="logo" />
                        <span className='textMenu' onClick={openNav}>&#9776; Menú</span>
                    </div>
                    <div className='usuario' >
                        <div className="cerrar" onClick={isAuthenticatedDispach}>
                            <IoExitOutline />
                            <p className="btnClose" >Cerrar sesión</p>
                        </div>

                        <div className="nameUser">
                            <LiaUserCircleSolid />
                            <p>{user}</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Navbar;
