/* -------------------- dependencias -------------------- */
import React, { useEffect, useState } from 'react';
import Axios from '../../../../../Configuracion/Axios/Axios';
import { GENERAL_URL_IMAGE, GET_URL_COMPANY, PUT_URL_IMAGE } from '../../../../../Constancias/Rutas/Rutas';
import Swal from 'sweetalert2';

/*------------------- componentes --------------- */

/*------------------- rutas --------------- */




/*---------------------------------- logica ---------------------------------------- */

export const ActualizarImagenes = (props) => {

    const back = () => {
        document.getElementById("Tabla_Datos_3").style.display = "flex";
        document.getElementById("botonesEleccion").style.display = "flex";
        document.getElementById("contentImagenesUpdate").style.display = "none";
    }

    /*-------------------------------------------- trae los datos de una imagen --------------------------------------------- */
    const [filterImagen, setFilterImagen] = useState([]);
    const [imagenes, setImagenes] = useState([]);
    const [images, setImages] = useState('');
    const idImages = parseInt((images), 10);

    const handleChangeImagenId = (event) => {
        const selectedImageId = event.target.value;
        setImages(selectedImageId);
    };

    useEffect(() => {
        const fetchImagenes = async () => {
            try {
                const response = await Axios.get(GENERAL_URL_IMAGE);
                if (response.status === 200) {
                    setImagenes(response.data.images)
                    setFilterImagen(response.data.images.filter(image => image.id === idImages));
                }
            } catch (error) {
                console.error('Error en la solicitud:', error);
            }
        };
        fetchImagenes();
    }, [idImages]);

    /*-------------------------------------------- traer los datos de la compañia --------------------------------------------- */
    // const [filterCompañias, setFilterCompañias] = useState('');
    const [companies, setCompanies] = useState([]);

    useEffect(() => {
        const fetchCompanies = async () => {
            try {
                const response = await Axios.get(GET_URL_COMPANY);
                if (response) {
                    setCompanies(response.data.compañias);

                    // if (filterImagen) {
                    //     setFilterCompañias(response.data.compañias.filter(company => company.id === ));
                    //     const filteredCompany = allCompanies.find(company => company.id === idCompany);
                    //     setFilterCompañias(filteredCompany)
                    // }
                }
            } catch (error) {
                console.error('Error en la solicitud:', error);
                Swal.fire({
                    icon: 'error',
                    title: 'Error al cargar las compañías',
                    text: 'Hubo un problema al cargar las compañías. Por favor, inténtalo de nuevo.'
                });
            }
        };
        fetchCompanies();
    }, []);

    /*-------------------------------------------- actualizar imagenes --------------------------------------------- */

    const [datos, setDatos] = useState({
        id_compania: '',
        url: '',
        nombre_foto: '',
    });

    const handleChangeImagen = (e) => {
        setDatos({
            ...datos,
            [e.target.name]: e.target.value,
        });
    };

    const handlePostImagenUpdate = async (e) => {
        e.preventDefault();
        try {
            const response = await Axios.put(`${PUT_URL_IMAGE}?id=${idImages}`, datos);
            console.log('Respuesta del servidor:', response.data);
            if (response) {
                Swal.fire({
                    icon: "success",
                    title: "Haz actualizado una imagen correctamente",
                    showConfirmButton: false,
                    timer: 1000
                });
                setTimeout(function () {
                    setDatos('');
                    window.location.reload();
                }, 1000);
            }
        } catch (error) {
            console.error('Error:', error);
            Swal.fire({
                title: `Ups... hubo un problema, revisa bien los parametros`,
                icon: 'error',
                toast: true,
                position: 'top',
                showConfirmButton: false,
                width: 560,
                timer: 2800,
                stopKeydownPropagation: true,
                showClass: {
                    popup: 'animate__animated animate__fadeInDown'
                },
                hideClass: {
                    popup: 'animate__animated animate__fadeOutUp'
                },
                customClass: {
                    popup: 'Content_Swall_error',
                }
            });
        }
    };

    return (
        <>
            <form onSubmit={handlePostImagenUpdate} className='formConfiguracion'>
                <label htmlFor="id_usuario">Selecciona la imagen que quieres editar:</label>
                <select
                    id="id_compania2"
                    name="id_compania"
                    value={images}
                    onChange={handleChangeImagenId}

                    className='selectUser'
                >
                    <option value="" disabled>Seleccionar una imagen</option>
                    {imagenes.map(imagen => (
                        <option key={imagen.id} value={imagen.id}>
                            {imagen.nombre_foto}
                        </option>
                    ))}
                </select>
                <label htmlFor="id_usuario">Selecciona la compañía que le pertenezca esa imagen:</label>
                <select
                    id="id_compania3"
                    name="id_compania"
                    value={images.id_compania}
                    onChange={handleChangeImagen}
                    required
                    className='selectUser'
                >
                    <option value="" disabled>Seleccionar una compañía</option>
                    {companies.map(company => (
                        <option key={company.id} value={company.id}>
                            {company.nombre}
                        </option>
                    ))}
                </select>
                <label htmlFor="url">URL:</label>
                <input
                    type="text"
                    id="url2"
                    name="url"
                    placeholder={filterImagen.length > 0 ? filterImagen[0].url : ''}
                    value={datos.url}
                    onChange={handleChangeImagen}
                />
                <label htmlFor="nombre_foto">Nombre de la Foto:</label>
                <input
                    type="text"
                    id="nombre_foto2"
                    name="nombre_foto"
                    minLength={4}
                    placeholder={filterImagen.length > 0 ? filterImagen[0].nombre_foto : ''}
                    value={datos.nombre_foto}
                    onChange={handleChangeImagen}
                />
                <button type="submit">Actualizar Imagen</button>
            </form>
            <button className='backForms_1' onClick={back}>Atras</button>
        </>
    )
}