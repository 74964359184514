/* -------------------- dependencias -------------------- */
import React, { useEffect, useState } from 'react';

/*------------------- componentes --------------- */
import Axios from '../../../../../Configuracion/Axios/Axios';

/*------------------- rutas --------------- */
import { GENERAL_URL_IMAGE, GET_URL_COMPANY } from '../../../../../Constancias/Rutas/Rutas';
import { botonCerrado } from '../../../../Paginas/Configuracion/Acciones';


export const ELiminarImagenes = (props) => {

    const back = () => {
        document.getElementById("botonesEleccion").style.display = "flex";
        document.getElementById("Tabla_Datos_3").style.display = "flex";
        document.getElementById("contentImagenesDelete").style.display = "none";
    }

    /*-------------------------------------------- traer los datos una compañia --------------------------------------------- */
    const [companies, setCompanies] = useState([]);
    const [company, setCompany] = useState('');
    const idCompany = parseInt((company), 10);
    // console.log(idCompany);

    const handleChangeCompanyUp = (event) => {
        const selectedCompanyId = event.target.value;
        setCompany(selectedCompanyId);
    };

    useEffect(() => {
        const fetchCompanies = async () => {
            try {
                const response = await Axios.get(GET_URL_COMPANY);
                if (response.status === 200) {
                    const allCompanies = response.data.compañias;
                    setCompanies(allCompanies);
                }
            } catch (error) {
                console.warn('Error en la solicitud:', error);
            }
        };
        fetchCompanies();
    }, [idCompany]);

    /*-------------------------------------------- trae los datos de una imagen --------------------------------------------- */

    const [imagenes, setImagenes] = useState([]);
    const [images, setImages] = useState('');
    const idImages = parseInt((images), 10);
    console.log(idImages);

    const handleChangeImagenId = (event) => {
        const selectedImageId = event.target.value;
        setImages(selectedImageId);
    };

    useEffect(() => {
        const fetchImagenes = async () => {
            try {
                const response = await Axios.get(GENERAL_URL_IMAGE);
                if (response.status === 200) {
                    setImagenes(response.data.images.filter((image) => image.id_compania === idCompany))
                }
            } catch (error) {
                console.error('Error en la solicitud:', error);
            }
        };
        fetchImagenes();
    }, [idCompany]);

    /*-------------------------------------------- eliminar una imagen --------------------------------------------- */



    return (
        <>
            <div>
                <form className='formConfiguracion'>
                    <label htmlFor="id_usuario">Selecciona la compañía que tenga las imagenes:</label>
                    <select
                        id="compañiaEliminar"
                        name="compañiaEliminar"
                        value={company}
                        onChange={handleChangeCompanyUp}
                        className='selectUser'
                    >
                        <option value="" disabled>Seleccionar una compañia</option>
                        {companies.map(companies => (
                            <option key={companies.id} value={companies.id}>
                                {companies.nombre}
                            </option>
                        ))}
                    </select>
                    <label htmlFor="id_usuario">Selecciona la imagen que quieres eliminar:</label>
                    <select
                        id="ImagenEliminar"
                        name="ImagenEliminar"
                        value={images}
                        required
                        onChange={handleChangeImagenId}
                        className='selectUser'
                    >
                        <option value="" disabled>Seleccionar una imagen</option>
                        {imagenes.map(imagen => (
                            <option key={imagen.id} value={imagen.id}>
                                {imagen.nombre_foto}
                            </option>
                        ))}
                    </select>
                    <button onClick={botonCerrado}>Eliminar Imagen</button>
                </form>
            </div>
            <button className='backForms_1' onClick={back}>Atras</button>
        </>
    )
}