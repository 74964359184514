import React from 'react';
import './PantallaCarga.css';

/*------------------- imagenes --------------- */
import logo from '../../../ImagenesInterfaz/Logo/logo_fondo_verde.gif'

const PantallaCarga = () => {
    return (
        <div className="spinner-container">
            <img src={logo} className="logo" alt="logo cargando" />
        </div>
    );
};

export default PantallaCarga;
