/* -------------------- dependencias -------------------- */
import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import './EstructuraComercial.css'
/*------------------- componentes --------------- */
import Navbar from '../../Ui/Navbar/Navbar';
import Footer from '../../Ui/Footer/Footer';
import { TipoEnvios } from '../../Funciones/CrudEstructuraComercial/TipoEnvios/CrearTiposEnvio/TipoEnvios';
import { EliminarTiposEnvio } from '../../Funciones/CrudEstructuraComercial/TipoEnvios/EliminarTiposEnvio/EliminarTiposEnvio';
import { TablaTipoEnvios } from '../../Funciones/CrudEstructuraComercial/Tablas/TablaTipoEnvios';

/*------------------- icons --------------- */
import { AiOutlineRightCircle, AiOutlineDelete } from "react-icons/ai";
import { IoCreateOutline } from "react-icons/io5";
import { RxUpdate } from "react-icons/rx";
/*------------------- acciones --------------- */
import { botonCerrado, crearTipoEnvio, dontSee, eliminarTipoEnvio, seeTiposEnvios } from './Acciones';



export const EstructuraComercial = (props) => {

    /*-------------------------------------------- localStorage --------------------------------------------- */
    const [ids, setId] = useState('');
    const [rolUsuario, setRol] = useState('');

    useEffect(() => {
        const obtenerUsuarioDesdeLocalStorage = () => {
            const storedObjectString = localStorage.getItem('userObject');
            if (storedObjectString) {
                const userObject = JSON.parse(storedObjectString);
                setId(userObject.auth)
                setRol(userObject.account_plan_user_role)
            } else {
                console.log('No se encontró el objeto en el localStorage');
            }
        };
        obtenerUsuarioDesdeLocalStorage();
    }, []);

    const { id, rol } = useParams();
    const urlId = parseInt(id)
    if (ids === urlId && rol === rolUsuario && rol === "superAdmin") {
        return (
            <>
                <Navbar />
                <div className='mainContenAyuda'>
                    <div className='titleMainInicioAyuda'>
                        <h1>Estructura comercial</h1>
                    </div><hr />
                    <div className='contentCardsDashboard'>
                        <div className="contentCardConfiguracionOptions" onClick={seeTiposEnvios}>
                            <AiOutlineRightCircle className='iconConfiguracion' />
                            <h3>Tipo de envio</h3>
                        </div>
                        <div className="contentCardConfiguracionOptions" >
                            <h3>Vacío</h3>
                        </div>
                        <div className="contentCardConfiguracionOptions" >
                            <h3>Vacío</h3>
                        </div>
                        <div className="contentCardConfiguracionOptions" >
                            <h3>Vacío</h3>
                        </div>
                        <div className="contentCardConfiguracionOptions" >
                            <h3>Vacío</h3>
                        </div>
                        <div className="contentCardConfiguracionOptions" >
                            <h3>Vacío</h3>
                        </div>
                    </div>
                </div>

                <div id='all'>
                    <div id='contentTipoEnvio'>
                        <div className='contentTitle'>
                            <h1>Opciones de tipos de envio</h1> <p className="btnCloseVista" onClick={dontSee}>Salir</p>
                        </div><hr />
                        {/* ------------------------ crud de tipos de imagenes opciones ------------------------------ */}
                        <div id='botonesEleccionComercial'>
                            <div className="contentCardConfiguracionOptions" onClick={botonCerrado}>
                                <RxUpdate className='iconConfiguracion' />
                                <h3>Actualizar</h3>
                            </div>
                            <div className="contentCardConfiguracionOptions" onClick={crearTipoEnvio}>
                                <IoCreateOutline className='iconConfiguracion' />
                                <h3>Crear</h3>
                            </div>
                            <div className="contentCardConfiguracionOptions" onClick={eliminarTipoEnvio}>
                                <AiOutlineDelete className='iconConfiguracion' />
                                <h3>Eliminar</h3>
                            </div>

                        </div>
                        <div id='Tabla_Datos'>
                            <TablaTipoEnvios />
                        </div>

                        <div id='contentCrearTipos'>
                            <TipoEnvios />
                        </div>
                        <div id='contentEliminarTipos'>
                            <EliminarTiposEnvio />
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        )
    }
}