/* -------------------- dependencias -------------------- */
import React, { useEffect, useState } from 'react';

/*------------------- componentes --------------- */
import Axios from '../../../../Configuracion/Axios/Axios';

/*------------------- imagenes --------------- */
import { GENERAL_URL_IMAGE } from '../../../../Constancias/Rutas/Rutas';

export const Imagenes = (props) => {

    const [datos, setDatos] = useState([]);

    useEffect(() => {
      Axios.get(GENERAL_URL_IMAGE)
        .then((response) => {
          setDatos(response.data.images.reverse());
        })
        .catch((error) => {
          console.error('Error al obtener los datos: ', error);
        });
    }, []);

    return (
        <>
            <table className='tabla-tipoEnvio'>
                <thead>
                    <tr>
                        <th>Id</th>
                        <th>Id de compañia</th>
                        <th>Nombre</th>
                    </tr>
                </thead>
                <tbody>
                    {datos.map((item, index) => (
                        <tr key={index}>
                            <td>{item.id}</td>
                            <td>{item.id_compania}</td>
                            <td>{item.nombre_foto}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    )
}