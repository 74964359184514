/* -------------------- dependencias -------------------- */
import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import "../../../../Paginas/Configuracion/Configuracion.css";
/*------------------- componentes --------------- */
import Axios from '../../../../../Configuracion/Axios/Axios';

/*------------------- rutas --------------- */
import { GET_URL_TEMPLATE, GET_URL_USER } from '../../../../../Constancias/Rutas/Rutas';


/*---------------------------------- logica ---------------------------------------- */

export const CrearPlatilla = (props) => {

    const back = () => {
        document.getElementById("botonesEleccion").style.display = "flex";
        document.getElementById("CrearPlantillas").style.display = "none";
    }

    /*-------------------------------------------- traer todos los usuarios --------------------------------------------- */

    const [usuarios, setUsuarios] = useState([]);
    const [idUser, setIdUser] = useState('');
    const idUsers = parseInt((idUser), 10);

    const handleChangeUserUp = (event) => {
        const selecteduseryId = event.target.value;
        setIdUser(selecteduseryId);
    };

    useEffect(() => {
        const fetchUsuarios = async () => {
            try {
                const response = await Axios.get(GET_URL_USER);
                setUsuarios(response.data.users);
            } catch (error) {
                console.error('Error al obtener la lista de usuarios:', error);
            }
        };

        fetchUsuarios();
    }, []);


    /*-------------------------------------------- plantillas --------------------------------------------- */
    const [plantillaData, setPlantillaData] = useState({
        nombrePlantilla: ''
    });

    const handleChangePlantillas = (e) => {
        const { name, value } = e.target;
        setPlantillaData({
            ...plantillaData,
            [name]: value
        });
    };

    const handleSubmitPlantillas = async (e) => {
        e.preventDefault();
        try {
            const data = {
                id_usuario: idUsers,
                name: plantillaData.nombrePlantilla
            }
            console.log(data);
            const response = await Axios.post(GET_URL_TEMPLATE, data);
            console.log('Respuesta del servidor:', response.data);

            if (response) {
                Swal.fire({
                    icon: "success",
                    title: "Haz creado una plantilla correctamente",
                    showConfirmButton: false,
                    timer: 1500
                });
                setTimeout(function () {
                    setPlantillaData('');
                    window.location.reload();
                }, 1000);
            }
        } catch (error) {
            console.error('Error en la solicitud:', error);
            Swal.fire({
                title: `Ups... hubo un problema, revisa bien los parametros`,
                icon: 'error',
                toast: true,
                position: 'top',
                showConfirmButton: false,
                width: 560,
                timer: 2800,
                stopKeydownPropagation: true,
                showClass: {
                    popup: 'animate__animated animate__fadeInDown'
                },
                hideClass: {
                    popup: 'animate__animated animate__fadeOutUp'
                },
                customClass: {
                    popup: 'Content_Swall_error',
                }
            });
        }
    };

    return (
        <>
            <div>
                <form onSubmit={handleSubmitPlantillas} className='formConfiguracion'>
                    <label htmlFor="id_usuario">Escoge el usuario que quieres que vea esa plantilla:</label>
                    <select
                        id="id_usuarioplantilla"
                        name="id_usuarioplantilla"
                        value={idUser}
                        onChange={handleChangeUserUp}
                        required
                        className='selectUser'
                    >
                        <option value="" disabled>Seleccionar usuario</option>
                        {usuarios.map(usuario => (
                            <option key={usuario.id} value={usuario.id}>
                                {usuario.nombre}
                            </option>
                        ))}
                    </select>
                    <label htmlFor="nombrePlantilla">Nombre de la plantilla:</label>
                    <input
                        type="text"
                        id="nombrePlantilla"
                        name="nombrePlantilla"
                        value={plantillaData.nombrePlantilla}
                        onChange={handleChangePlantillas}
                        required
                    />
                    <button type="submit">Crear plantilla</button>
                </form>
            </div>
            <button className='backForms' onClick={back}>Atras</button>

        </>
    )
}