/*-------------------------------------------- acciones de ver y no ver --------------------------------------------- */
import Swal from "sweetalert2";

export const dontSee = () => {
    window.location.reload();
}
export const seeTiposEnvios = () => {
    document.getElementById("contentTipoEnvio").style.display = "flex";
    document.getElementById("all").style.display = "flex";
    document.getElementById("botonesEleccionComercial").style.display = "flex";
    document.getElementById("Tabla_Datos").style.display = "flex";
}
export const crearTipoEnvio = () => {
    document.getElementById("contentCrearTipos").style.display = "flex";
    document.getElementById("botonesEleccionComercial").style.display = "none";
    document.getElementById("Tabla_Datos").style.display = "none";
}
export const eliminarTipoEnvio = () => {
    document.getElementById("contentEliminarTipos").style.display = "flex";
    document.getElementById("botonesEleccionComercial").style.display = "none";
    document.getElementById("Tabla_Datos").style.display = "none";
}
export const botonCerrado = () => {
    Swal.fire("Ups...  este boton no esta disponible en este momento");
}
