/* --------------- Dependecias ------------------ */
import './App.css';
// import Cookies from 'js-cookie';
import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
/*----------------------- Components ---------------------- */
import { Login } from "../src/Componentes/Paginas/Login/Login";
import { Inicio } from "../src/Componentes/Paginas/Inicio/Inicio";
import { TusDatos } from './Componentes/Paginas/TusDatos/TusDatos';
import { Dashboard } from './Componentes/Paginas/Dashboard/Dashboard';
import { Ayuda } from './Componentes/Paginas/Ayuda/Ayuda';
import { Configuracion } from './Componentes/Paginas/Configuracion/Configuracion';
import { EstructuraComercial } from './Componentes/Paginas/EstructuraComercial/EstructuraComercial';

/*----------------------- localStorage ---------------------- */
// const validacion_login = localStorage.getItem("login")

function App() {

  const [validacion_login, setvalidacion_login] = useState('');
  // const [data, setData] = useState({});

  useEffect(() => {
    const obtenerUsuarioDesdeLocalStorage = () => {
      const storedObjectString = localStorage.getItem('userObject');
      if (storedObjectString) {
        const userObject = JSON.parse(storedObjectString);
        setvalidacion_login(userObject.login)
      } else {
        return;
      }
    };
    obtenerUsuarioDesdeLocalStorage();
  }, []);

  // const checkCookieAndSetLocalStorage = () => {
  //   const userObjectCookie = Cookies.get('userObject');

  //   if (!userObjectCookie) {
  //     // Si la cookie no existe, eso significa que la sesión ha expirado o ha sido borrada.
  //     // Limpia localStorage y cierra la sesión
  //     localStorage.clear();
  //   } else {
  //     // Si la cookie existe, actualiza localStorage
  //     const userObject = JSON.parse(userObjectCookie);
  //     localStorage.setItem("setId", userObject.auth);
  //     localStorage.setItem("userObject", userObjectCookie);
  //   }
  // };

  // useEffect(() => {
  //   // Verificar la cookie al montar el componente
  //   checkCookieAndSetLocalStorage();

  //   // Establecer un intervalo para verificar la cookie regularmente
  //   const intervalId = setInterval(checkCookieAndSetLocalStorage, 1000 * 60 * 15); // cada 15 minutos

  //   // Limpiar el intervalo cuando el componente se desmonta
  //   return () => {
  //     clearInterval(intervalId);
  //   };
  // }, []);

  if (!window.consoleMessageShown) {
    console.log('%cADVERTENCIA', 'color: red; font-size: 24px; font-weight: bold;');
    console.log('%cSi usas esta consola, un atacante podría suplantar tu identidad y robar tu información a través de un ataque llamado Self-XSS. No ingreses ni pegues ningún código que no entiendas.', 'color: yellow; font-size: 18px;');
    window.consoleMessageShown = true;
  }
  
  return (
    <>
      <BrowserRouter>
        <Routes>

          {/* contentino error 404*/}
          <Route path="/*" element={<Login />} />

          {/* contenido de la pagina*/}
          <Route path="/" element={validacion_login ? <Dashboard /> : <Login />} />
          <Route path="/Dashboard" element={validacion_login ? <Dashboard /> : <Login />} />
          <Route path="/inicio" element={validacion_login ? <Inicio /> : <Login />} />
          <Route path="/datos" element={validacion_login ? <TusDatos /> : <Login />} />
          <Route path="/ayuda" element={validacion_login ? <Ayuda /> : <Login />} />
          <Route path="/configuracion/auth/:id/page/:rol" element={validacion_login ? <Configuracion /> : <Login />} />
          <Route path="/estructuraComercial/auth/:id/page/:rol" element={validacion_login ? <EstructuraComercial /> : <Login />} />

        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
