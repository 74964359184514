/* -------------------- dependencias -------------------- */
import { isAfter, isBefore, parseISO, format } from 'date-fns';
import React, { useEffect, useState } from 'react';
/*------------------- componentes --------------- */
import Axios from '../../../Configuracion/Axios/Axios';
import { Loading } from "../../Ui/Loading/Loading";
/*------------------- rutas --------------- */
import { GET_URL_REPORT_SEND, GET_URL_USER } from '../../../Constancias/Rutas/Rutas';

export const ReporteEnvios = (props) => {

    const Volver = () => {
        document.getElementById("ContentEnvios").style.display = "none"
        document.getElementById("ContentReporteNotification").style.display = "none"
    }

    /*--------------------------------------------- traer el usuario ligado --------------------------------------------- */
    const [users, setUser] = useState([]);
    const idUser = parseInt(localStorage.getItem("setId"), 10);

    useEffect(() => {
        const fetchUsuarios = async () => {
            try {
                const response = await Axios.get(GET_URL_USER);

                if (response.status === 200) {
                    const usuariosFiltrados = idUser
                        ? response.data.users.filter(user => user.id === parseInt(idUser, 10))
                        : response.data.users;

                    setUser(usuariosFiltrados);
                }
            } catch (error) {
                console.error('Error en la solicitud:', error.message);
            }
        };

        fetchUsuarios();
    }, [idUser]);

    /*--------------------------------------------- traer todos los reportes de envio --------------------------------------------- */
    const [loading, setLoading] = useState(false);
    const [datos, setDatos] = useState([]);
    const [filtroNombre, setFiltroNombre] = useState('');
    const [filtroFechaDesde, setFiltroFechaDesde] = useState('');
    const [filtroFechaHasta, setFiltroFechaHasta] = useState('');
    const [costo, setCosto] = useState(0);
    const [totalMensajesCelulares, setTotalMensajesCelulares] = useState(0);

    useEffect(() => {
        // Validar que users[0] y users[0].nombre estén definidos antes de realizar la solicitud
        if (!users[0] || !users[0].nombre) {
            // console.warn('users[0] o users[0].nombre no está definido. No se realizará la solicitud de datos.');
            return;
        }
        const fetchData = async () => {
            try {
                setLoading(true);

                const response = await Axios.get(GET_URL_REPORT_SEND);
                if (response.status === 200) {
                    // Verificar si la longitud de la respuesta es 0
                    if (response.data.estatus.length === 0) {
                        console.log('No hay datos disponibles.'); // O cualquier otra acción que desees realizar
                        return;
                    }
                    const datosConFechasConvertidas = response.data.estatus.reverse().filter((user) => user.user === (users[0] ? users[0].nombre : null));

                    // // Filtros generales
                    const datosFiltrados = datosConFechasConvertidas.filter(item =>
                        (!filtroNombre || item.nombre.toLowerCase().includes(filtroNombre.toLowerCase())) &&
                        (!filtroFechaDesde || isAfter(parseISO(item.createdAt), parseISO(filtroFechaDesde))) &&
                        (!filtroFechaHasta || isBefore(parseISO(item.createdAt), parseISO(filtroFechaHasta)))
                    );

                    // Sumar los números de la propiedad 'celular' de los objetos en datosFiltrados
                    const cantidadElementos = datosFiltrados.length;
                    setTotalMensajesCelulares(cantidadElementos);
                    setDatos(datosFiltrados);
                    setCosto(0);
                }
            } catch (error) {
                if (error.response) {
                    // El servidor respondió con un código de estado diferente de 2xx
                    console.error('Error al obtener datos:', error.response.status);
                }
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [users, filtroNombre, filtroFechaDesde, filtroFechaHasta]);

    // -------------------------------------------- Función para dar formato a la fecha --------------------------------------------
    // const formatDate = (dateString) => {
    //     const options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', timeZone: 'UTC' };
    //     const formattedDate = new Date(dateString).toLocaleDateString('es-ES', options);
    //     return formattedDate;
    // };

    return (
        <>
            <div className='mainContenDaskboard'>
                <div className='titleMainInicio'>
                    <h1>Envios</h1>
                    <div className='filtrosReportes'>
                        <input
                            className='selectDatosPrincipalesDataReportes'
                            type="text"
                            placeholder="Buscar por nombre"
                            value={filtroNombre}
                            onChange={(e) => setFiltroNombre(e.target.value)}
                        />
                        <label htmlFor="date">Desde:</label>
                        <input
                            className='selectDatosPrincipalesDataReportes'
                            type="date"
                            value={filtroFechaDesde}
                            onChange={(e) => setFiltroFechaDesde(e.target.value)}
                        />
                        <label htmlFor="date">Hasta:</label>
                        <input
                            className='selectDatosPrincipalesDataReportes'
                            type="date"
                            value={filtroFechaHasta}
                            onChange={(e) => setFiltroFechaHasta(e.target.value)}
                        />
                    </div>
                    <p className="volver-reportes" onClick={Volver}>Volver</p>
                </div><hr />
                <div className='Conten-datos-filtrados'>
                    <div className='text-content-reportes'>
                        <p><b>Mensajes enviados (por celulares):</b> {totalMensajesCelulares}</p>
                        <p><b>Filtrado por fecha:</b> {!filtroFechaDesde ? "Todas las fechas" : "Desde: " + filtroFechaDesde + " - Hasta: " + filtroFechaHasta}</p>
                        <p id='text-no-funtion'><b>Costo:</b>{costo === 0 ? " No disponible" : costo}</p>
                    </div>
                </div>
                {!loading
                    ? <div className='tableContainer-reportes'>
                        {/* Tabla */}
                        <table className='tableAll'>
                            {datos.length > 0 ? (
                                <>
                                    <thead>
                                        <tr>
                                            <th>Nombre</th>
                                            <th>Tipo de envio</th>
                                            <th>Numero</th>
                                            <th>Fecha</th>
                                            <th>Hora</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {datos.map((item, index) => (
                                            <tr key={index}>
                                                <td>{item.nombre}</td>
                                                <td>{item.descriptivo}</td>
                                                <td>{item.celular}</td>
                                                <td>{format(new Date(item.createdAt), 'dd/MM/yyyy')}</td>
                                                <td>{format(new Date(item.createdAt), 'HH:mm:ss')}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </>
                            ) : (
                                <tbody>
                                    <tr>
                                        <td colSpan="2">No se encontraron datos</td>
                                    </tr>
                                </tbody>
                            )}
                        </table>
                    </div>
                    : <Loading />
                }
            </div>
        </>
    )
}