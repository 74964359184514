/* -------------------- dependencias -------------------- */
import React, { useEffect, useState } from 'react';

/*------------------- componentes --------------- */
import Axios from '../../../../Configuracion/Axios/Axios';

/*------------------- imagenes --------------- */
import { GET_URL_USER } from '../../../../Constancias/Rutas/Rutas';

export const Usuarios = (props) => {

    const [datos, setDatos] = useState([]);

    useEffect(() => {
      Axios.get(GET_URL_USER)
        .then((response) => {
          setDatos(response.data.users.reverse());
        })
        .catch((error) => {
          console.error('Error al obtener los datos: ', error);
        });
    }, []);

    return (
        <>
            <table className='tabla-tipoEnvio'>
                <thead>
                    <tr>
                        <th>Id</th>
                        <th>Nombre</th>
                        <th>Estado</th>
                        <th>Correo</th>
                    </tr>
                </thead>
                <tbody>
                    {datos.map((item, index) => (
                        <tr key={index}>
                            <td>{item.id}</td>
                            <td>{item.nombre}</td>
                            <td>{item.is_active ? "Activo" : "Inactivo"}</td>
                            <td>{item.correo}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    )
}