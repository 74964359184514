/* -------------------- dependencias -------------------- */
import React, { useState } from 'react';
import Swal from 'sweetalert2';

/*------------------- componentes --------------- */
import Axios from '../../../../../Configuracion/Axios/Axios';

/*------------------- rutas --------------- */
import { GET_URL_USER } from '../../../../../Constancias/Rutas/Rutas';




export const CrearUsuario = (props) => {

    const back = () => {
        document.getElementById("botonesEleccion").style.display = "flex";
        document.getElementById("Tabla_Datos").style.display = "flex";
        document.getElementById("createUsuario").style.display = "none";
    }

    /*---------------------------------- crear un usuario ---------------------------------------- */

    const [formData, setFormData] = useState({
        correo: '',
        contrasena: '',
        nombre: '',
        rol: ''
    });
    // console.log(formData);
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (formData.contrasena.length < 8) {
            Swal.fire({
                title: `La contraseña debe ser de 8 caracteres`,
                icon: 'info',
                toast: true,
                position: 'top',
                showConfirmButton: false,
                width: 560,
                timer: 1800,
                stopKeydownPropagation: true,
                showClass: {
                    popup: 'animate__animated animate__fadeInDown'
                },
                hideClass: {
                    popup: 'animate__animated animate__fadeOutUp'
                },
                customClass: {
                    popup: 'Content_Swall_error',
                }
            });
            return;
        }
        try {
            const response = await Axios.post(GET_URL_USER, formData);
            console.log('Respuesta del servidor:', response.data);
            if (response) {
                Swal.fire({
                    icon: "success",
                    title: "Haz creado un usuario correctamente",
                    showConfirmButton: false,
                    timer: 1500
                });

                setTimeout(function () {
                    setFormData('');
                    window.location.reload();
                }, 1000);
            }
        } catch (error) {
            if (error.response) {
                // El servidor respondió con un código de estado fuera del rango 2xx
                console.error('Respuesta del servidor con error:', error.response.data);
                Swal.fire({
                    title: `Ups... hubo un problema, revisa bien los parametros`,
                    icon: 'error',
                    toast: true,
                    position: 'top',
                    showConfirmButton: false,
                    width: 560,
                    timer: 2800,
                    stopKeydownPropagation: true,
                    showClass: {
                        popup: 'animate__animated animate__fadeInDown'
                    },
                    hideClass: {
                        popup: 'animate__animated animate__fadeOutUp'
                    },
                    customClass: {
                        popup: 'Content_Swall_error',
                    }
                });
            } else if (error.request) {
                // La solicitud fue realizada pero no se recibió respuesta
                console.error('No se recibió respuesta del servidor:', error.request);
                Swal.fire({
                    title: `Ups... hubo un problema, No se recibió respuesta del servidor`,
                    icon: 'error',
                    toast: true,
                    position: 'top',
                    showConfirmButton: false,
                    width: 560,
                    timer: 2800,
                    stopKeydownPropagation: true,
                    showClass: {
                        popup: 'animate__animated animate__fadeInDown'
                    },
                    hideClass: {
                        popup: 'animate__animated animate__fadeOutUp'
                    },
                    customClass: {
                        popup: 'Content_Swall_error',
                    }
                });
            } else {
                // Ocurrió un error antes de enviar la solicitud
                console.error('Error en la solicitud:', error.message);
            }
        }
    };
    return (
        <>
            <form onSubmit={handleSubmit} className='formConfiguracion'>
                <label htmlFor="correo">Correo:</label>
                <input
                    type="email"
                    id="correo"
                    name="correo"
                    value={formData.correo}
                    onChange={handleChange}
                    required
                    autoComplete="username"
                />
                <label htmlFor="contrasena">Contraseña:</label>
                <input
                    type="password"
                    id="contrasena"
                    name="contrasena"
                    value={formData.contrasena}
                    onChange={handleChange}
                    required
                    autoComplete="current-password"
                />
                <label htmlFor="nombre">Nombre:</label>
                <input
                    type="text"
                    id="nombre"
                    name="nombre"
                    minLength={4}
                    value={formData.nombre}
                    onChange={handleChange}
                    required
                />
                <label htmlFor="rol">Rol:</label>
                <select
                    id="rol"
                    name="rol"
                    value={formData.rol}
                    onChange={handleChange}
                    required
                    className='selectUser'
                >
                    <option value="" style={{ color: '#00a878' }}>Escoje el rol del usuario</option>
                    <option value="superAdmin" style={{ color: '#00a878' }}>SuperAdmin</option>
                    {/* <option value="piloto" style={{ color: '#00a878' }}>Piloto</option> */}
                    <option value="admin" style={{ color: '#00a878' }}>Piloto</option>
                </select>
                <button type="submit">Registrar usuario</button>
            </form>
            <button className='backForms_' onClick={back}>Atras</button>
        </>
    )
}