/* -------------------- dependencias -------------------- */
import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';

/*------------------- componentes --------------- */
import Axios from '../../../../../Configuracion/Axios/Axios';

/*------------------- rutas --------------- */
import { GET_URL_COMPANY, GET_URL_USER } from '../../../../../Constancias/Rutas/Rutas';


export const CrearCompañias = (props) => {

    const back = () => {
        document.getElementById("botonesEleccion").style.display = "flex";
        document.getElementById("Tabla_Datos_2").style.display = "flex";
        document.getElementById("crearCompañia").style.display = "none";
    }

    /*-------------------------------------------- traer todos los usuarios --------------------------------------------- */
    const [usuarios, setUsuarios] = useState([]);

    useEffect(() => {
        const fetchUsuarios = async () => {
            try {
                const response = await Axios.get(GET_URL_USER);
                setUsuarios(response.data.users);
            } catch (error) {
                console.error('Error al obtener la lista de usuarios:', error);
            }
        };

        fetchUsuarios();
    }, []);
    /*-------------------------------------------- compañia post --------------------------------------------- */

    const [companyData, setCompanyData] = useState({
        nombre: '',
        host: '',
        bd: '',
        id_usuario: '',
        token: '',
        api: '',
        wh_account: '',
        wh_number: ''
    });

    const handleChangeCompany = (e) => {
        const { name, value } = e.target;
        setCompanyData({
            ...companyData,
            [name]: value
        });
    };

    const handleSubmitCompany = async (e) => {
        e.preventDefault();
        if (companyData.nombre.length < 3) {
            Swal.fire({
                title: `El nombre debe tener al menos 3 caracteres`,
                icon: 'info',
                toast: true,
                position: 'top',
                showConfirmButton: false,
                width: 560,
                timer: 1800,
                stopKeydownPropagation: true,
                showClass: {
                    popup: 'animate__animated animate__fadeInDown'
                },
                hideClass: {
                    popup: 'animate__animated animate__fadeOutUp'
                },
                customClass: {
                    popup: 'Content_Swall_error',
                }
            });
            return;
        }
        try {
            const response = await Axios.post(GET_URL_COMPANY, companyData);
            console.log('Respuesta del servidor:', response.data);
            if (response) {
                Swal.fire({
                    icon: "success",
                    title: "Haz creado una compañia correctamente",
                    showConfirmButton: false,
                    timer: 1500
                });
                setTimeout(function () {
                    setCompanyData('');
                    window.location.reload();
                }, 1000);
            }
        } catch (error) {
            console.error('Error en la solicitud:', error);
            Swal.fire({
                title: `Ups... hubo un problema, revisa bien los parametros`,
                icon: 'error',
                toast: true,
                position: 'top',
                showConfirmButton: false,
                width: 560,
                timer: 2800,
                stopKeydownPropagation: true,
                showClass: {
                    popup: 'animate__animated animate__fadeInDown'
                },
                hideClass: {
                    popup: 'animate__animated animate__fadeOutUp'
                },
                customClass: {
                    popup: 'Content_Swall_error',
                }
            });
        }
    };

    return (
        <>
            <form onSubmit={handleSubmitCompany} className='formConfiguracion'>
                <label htmlFor="nombre1">Nombre de la compañía:</label>
                <input
                    type="text"
                    id="nombre1"
                    name="nombre"
                    value={companyData.nombre}
                    onChange={handleChangeCompany}
                    required
                    autoComplete="username"
                />
                <label htmlFor="host">Host:</label>
                <input
                    type="text"
                    id="host"
                    name="host"
                    value={companyData.host}
                    onChange={handleChangeCompany}
                    required
                />
                <label htmlFor="bd">Base de datos:</label>
                <input
                    type="text"
                    id="bd"
                    name="bd"
                    value={companyData.bd}
                    onChange={handleChangeCompany}
                    required
                />
                <label htmlFor="id_usuario">Usuario:</label>
                <select
                    id="id_usuario"
                    name="id_usuario"
                    value={companyData.id_usuario}
                    onChange={handleChangeCompany}
                    required
                    className='selectUser'
                >
                    <option value="" disabled>Seleccionar usuario</option>
                    {usuarios.map(usuario => (
                        <option key={usuario.id} value={usuario.id}>
                            {usuario.nombre}
                        </option>
                    ))}
                </select>
                <label htmlFor="token">Token:</label>
                <input
                    type="text"
                    id="token"
                    name="token"
                    value={companyData.token}
                    onChange={handleChangeCompany}
                    required
                />
                <label htmlFor="api">API:</label>
                <input
                    type="text"
                    id="api"
                    name="api"
                    value={companyData.api}
                    onChange={handleChangeCompany}
                    required
                />
                <label htmlFor="api">Numero de id de la compañia (wh_number):</label>
                <input
                    type="text"
                    id="number"
                    name="wh_number"
                    value={companyData.wh_number}
                    onChange={handleChangeCompany}
                    required
                />
                <label htmlFor="api">Numero de id de la cuenta business (wh_account - opcional):</label>
                <input
                    type="text"
                    id="numberCount"
                    name="wh_account"
                    value={companyData.wh_account}
                    onChange={handleChangeCompany}
                />
                <button type="submit">Registrar compañía</button>
            </form>
            <button className='backForms' onClick={back}>Atras</button>
        </>
    )
}