/* -------------------- dependencias -------------------- */
import React, { useEffect, useState } from 'react';
import './Reportes.css';
import { parseISO, isAfter, isBefore } from 'date-fns';
/*------------------- componentes --------------- */
import { Loading } from '../../Ui/Loading/Loading';
import Axios from '../../../Configuracion/Axios/Axios';
/*------------------- rutas --------------- */
import { GET_URL_REPORT } from '../../../Constancias/Rutas/Rutas';


export const Reportes = (props) => {

    const Volver = () => {
        // document.getElementById("all").style.display = "none"
        document.getElementById("ContentReporteNotification").style.display = "none"
        document.getElementById("ContentEnvios").style.display = "none"
    }

    /*---------------------------------- traer todos los reportes ---------------------------------------- */
    const [loading, setLoading] = useState(false);
    const [datos, setDatos] = useState([]);
    const [filtroNombre, setFiltroNombre] = useState('');
    const [filtroFechaDesde, setFiltroFechaDesde] = useState('');
    const [filtroFechaHasta, setFiltroFechaHasta] = useState('');
    const [costo, setCosto] = useState(0);
    const [totalMensajesEnviados, setTotalMensajesEnviados] = useState(0);
    const [totalMensajesCelulares, setTotalMensajesCelulares] = useState(0);
    const [filtroActivo, setFiltroActivo] = useState('todos'); // 'todos' mostrará todos los elementos por defecto
    const idUsers = parseInt(localStorage.getItem("setId"), 10);


    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
        
                const response = await Axios.get(GET_URL_REPORT);
                if (response.status === 200) {
                    const datosConFechasConvertidas = response.data.estatus.reverse().map(item => ({
                        ...item,
                        fecha: new Date(item.createdAt).toLocaleDateString(),
                    }));
        
                    setDatos(datosConFechasConvertidas);
                    setCosto(0);
        
                    // Filtros generales
                    const datosFiltrados = datosConFechasConvertidas.filter(item =>
                        (item.id_usuario === idUsers) &&
                        (!filtroNombre || item.nombre.toLowerCase().includes(filtroNombre.toLowerCase())) &&
                        (!filtroFechaDesde || isAfter(parseISO(item.createdAt), parseISO(filtroFechaDesde))) &&
                        (!filtroFechaHasta || isBefore(parseISO(item.createdAt), parseISO(filtroFechaHasta))) &&
                        (filtroActivo === 'todos' || (filtroActivo === 'activo' && item.estado === 1) || (filtroActivo === 'inactivo' && item.estado === 0))
                    );
        
                    // Calcular el total de mensajes de celulares solo para los datos filtrados
                    const totalMensajesCelularesFiltrados = datosFiltrados.reduce(
                        (total, item) => total + (item.celular ? item.celular.length : 0),
                        0
                    );
                    setTotalMensajesEnviados(datosFiltrados.length);
                    setTotalMensajesCelulares(totalMensajesCelularesFiltrados);
                    setDatos(datosFiltrados);
                }
            } catch (error) {
                console.error('Error al obtener datos:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [filtroNombre, filtroFechaDesde, filtroFechaHasta, filtroActivo, idUsers]);

    return (
        <>
            <div className='mainContenDaskboard'>
                <div className='titleMainInicio'>
                    <h1>Reportes</h1>
                    {/* Controles de filtro */}
                    <div className='filtrosReportes'>
                        <input
                            className='selectDatosPrincipalesDataReportes'
                            type="text"
                            placeholder="Buscar por nombre"
                            value={filtroNombre}
                            onChange={(e) => setFiltroNombre(e.target.value)}
                        />
                             <label htmlFor="date">Desde:</label>
                        <input
                            className='selectDatosPrincipalesDataReportes'
                            type="date"
                            value={filtroFechaDesde}
                            onChange={(e) => setFiltroFechaDesde(e.target.value)}
                        />
                             <label htmlFor="date">Hasta:</label>
                        <input
                            className='selectDatosPrincipalesDataReportes'
                            type="date"
                            value={filtroFechaHasta}
                            onChange={(e) => setFiltroFechaHasta(e.target.value)}
                        />
                        <select id="filtroActivo" className='selectDatosPrincipalesDataReportes' onChange={(e) => setFiltroActivo(e.target.value)}>
                            <option value="todos">Todos</option>
                            <option value="activo">Activo</option>
                            <option value="inactivo">Inactivo</option>
                        </select>
                    </div>
                    <p className="volver-reportes" onClick={Volver}>Volver</p>
                </div><hr />

                <div className='Conten-datos-filtrados'>
                    <div className='text-content-reportes'>
                        <p><b>Mensajes enviados (por notificación):</b> {totalMensajesEnviados}</p>
                        <p><b>Mensajes enviados (por celulares):</b> {totalMensajesCelulares}</p>
                        <p><b>Filtrado por fecha:</b> {!filtroFechaDesde ? "Todas las fechas" : "Desde: " + filtroFechaDesde + " - Hasta: " + filtroFechaHasta}</p>
                        <p id='text-no-funtion'><b>Costo:</b>{costo === 0 ? " No disponible" : costo}</p>
                    </div>
                </div>
                {!loading ?
                    <div className='tableContainer-reportes'>
                        {/* Tabla */}
                        <table className='tableAll'>
                            {datos.length > 0 ? (
                                <>
                                    <thead>
                                        <tr>
                                            <th>Notificación</th>
                                            <th>Calendario</th>
                                            <th>Estado</th>
                                            <th>Fecha</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {datos.map((item, index) => (
                                            <tr key={index}>
                                                <td>{item.nombre}</td>
                                                <td>{item.calendario === null || item.calendario === '' || item.calendario === ' ' ? "No tiene calendario" : item.calendario}</td>
                                                <td>{item.estado === 1 ? "Se activo" : "Se inactivo"}</td>
                                                <td>{item.fecha}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </>
                            ) : (
                                <tbody>
                                    <tr>
                                        <td colSpan="2">No se encontraron datos</td>
                                    </tr>
                                </tbody>
                            )}
                        </table>
                    </div>
                    : <Loading />}
            </div>
        </>
    )
}