/* -------------------- dependencias -------------------- */
import React from 'react';
import './Loading.css';
/*------------------- componentes --------------- */

/*------------------- imagenes --------------- */




/*---------------------------------- logica ---------------------------------------- */

export const Loading = (props) => {
    return (
        <>
            <div className="loading">
                <div className="loader"></div>
                <p>Cargando</p>
            </div>
        </>
    )
}