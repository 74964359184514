import React from 'react';
import './Footer.css';

const Footer = () => {
    return (
        <div className='pie-container'>
            <footer className="pie">
                <p className="text-secondary">
                    {"Copyright © "}
                    <a href="https://integroanalytics.my.canva.site/bitmeta" target='blank'>
                        BitMeta
                    </a>{" "}
                    {new Date().getFullYear()}
                </p>
            </footer>
        </div>
    );
};

export default Footer;
