/* -------------------- dependencias -------------------- */
import React from 'react';
import './Dashboard.css'
/*------------------- componentes --------------- */
import Navbar from '../../Ui/Navbar/Navbar';
import Footer from '../../Ui/Footer/Footer';
import { DashboardContent } from '../../Funciones/DashboardContent/DashboardContent';
import { Reportes } from '../../Funciones/ReporteNotificaciones/Reportes';
import { ReporteEnvios } from '../../Funciones/ReporteEnvios/ReporteEnvios';


export const Dashboard = (props) => {
    return (
        <>
            < Navbar />
            <div className='mainContenDaskboard'>
                <div className='titleMainInicio'>
                    <h1>Panel de control</h1>
                </div><hr />
                < DashboardContent />
            </div>
            <div>
                {/* <div id='all'> */}
                <div id='ContentReporteNotification'>
                    <Reportes />
                </div>
                {/* </div> */}
            </div>
            <div>
                <div id='ContentEnvios'>
                    <ReporteEnvios />
                </div>
            </div>
            < Footer />
        </>
    )
}