/* -------------------- dependencias -------------------- */
import React, { useState, useEffect } from 'react';
import './Notificaciones.css';
import { AiOutlineClockCircle, AiOutlineProject, AiOutlineDelete } from "react-icons/ai";
import { BsDatabaseExclamation, BsDatabaseCheck } from "react-icons/bs";
import { FaPlay } from "react-icons/fa";
import Swal from 'sweetalert2'; // Asegúrate de importar Swal desde la librería SweetAlert2

/*------------------- sub componentes de notificacion --------------- */
import IconoActivacion from '../Notificaciones/IconoActivacion';
import Axios from '../../../Configuracion/Axios/Axios';
import { ActualizarNoti } from './ActualizarNoti/ActualizarNoti';
import { ActualizarCalen } from './ActualizarCalen/ActualizarCalen';

/*------------------- Rutas --------------- */
import {
    DELETE_URL_MESSAGE,
    TRIGUER_POST_URL_MESSAGE_ACTIVE,
    TRIGUER_POST_URL_MESSAGE,
    GET_URL_USER,
} from "../../../Constancias/Rutas/Rutas";


/*------------------- imagenes --------------- */

export const Notificaciones = ({ datos, setRefresh }) => {

    const idUser = parseInt(localStorage.getItem("setId"), 10);

    /*---------------------------------- activacion de card ---------------------------------- */

    // const shouldTriggerPost = (data) => data.estado === 0 && data.is_active;
    // const shouldTrigger = (data) => data.estado === 1 && data.is_active && data.cron_active;

    // const triggerPost = async (data) => {
    //     const datas = {
    //         id: data.id
    //     };
    //     const response2 = await Axios.post(TRIGUER_POST_URL_MESSAGE_CRON, datas);
    //     const responseData2 = response2.data;
    //     setRefresh(false);
    //     console.log(responseData2);
    // };

    /*---------------------------------- confirmacion de la accion de activacion ---------------------------------------- */

    const showConfirmationDialog = async () => {
        return await Swal.fire({
            title: "¿Estás seguro?",
            text: "¿Quieres realizar esta acción?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Sí, quiero hacerlo",
            customClass: {
                container: 'Content_Swal_All'
            }
        });
    };

    /*---------------------------------- activacion de la card ---------------------------------------- */
    const fetchData = async (data) => {
        try {
            // if (shouldTriggerPost(data)) {
                const confirmacion = await showConfirmationDialog();

                if (confirmacion.isConfirmed) {
                    // await triggerPost(data);
                    const response = await Axios.get(`${TRIGUER_POST_URL_MESSAGE_ACTIVE}${data.id}`);
                    const responseData = response.data;
                    await Swal.fire({
                        title: "Realizado con éxito!",
                        icon: "success",
                        customClass: {
                            container: 'Content_Swal_All'
                        }
                    });
                    return responseData;
                }
            // } else if (shouldTrigger(data)) {
            //     await Swal.fire({
            //         icon: "info",
            //         title: "Espera unos segundos para actualizar tus datos<br>",
            //         customClass: {
            //             container: 'Content_Swal_All_alert',
            //             popup: 'Content_Swall_2',
            //         },
            //         timer: 1000,
            //         showConfirmButton: false,
            //         timerProgressBar: true
            //     }).then(() => {
            //         setRefresh(false);
            //         showConfirmationDialog();
            //     });
            // } else {
            //     const confirmacion = await showConfirmationDialog();
            //     if (confirmacion.isConfirmed) {
            //         const response = await Axios.get(`${TRIGUER_POST_URL_MESSAGE_ACTIVE}${data.id}`);
            //         const responseData = response.data;
            //         await Swal.fire({
            //             title: "Realizado con éxito!",
            //             icon: "success",
            //             customClass: {
            //                 container: 'Content_Swal_All'
            //             }
            //         });
            //         setRefresh(false);
            //         return responseData;
            //     }
            // }

        } catch (error) {
            console.error('Error en la solicitud: ', error);
        }
    };

    const handleActivacion = async (data) => {
        await fetchData(data);
        setRefresh(false);
    };



    /*--------------------------- borrado logico de la card ------------------------ */

    // const triggerPost2 = async (id) => {
    //     const datas = {
    //         id: id
    //     };
    //     const response2 = await Axios.post(TRIGUER_POST_URL_MESSAGE_CRON, datas);
    //     const responseData2 = response2.data;
    //     setRefresh(false);
    //     console.log(responseData2);
    // };

    const handleEliminarNotificacion = async (id) => {
        try {
            const confirmacion = await Swal.fire({
                title: "¿Estás seguro?",
                text: "Una vez borrado no lo podrás recuperar.",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Sí, borrar",
                customClass: {
                    container: 'Content_Swal_All'
                }
            });

            if (confirmacion.isConfirmed) {
                // await triggerPost2(id);
                const response = await Axios.post(`${DELETE_URL_MESSAGE}${id}`);
                const responseData = response.data;
                await Swal.fire({
                    title: "¡Eliminado!",
                    text: "Tu notificacion ha sido eliminada.",
                    icon: "success",
                    customClass: {
                        container: 'Content_Swal_All'
                    }

                });
                setRefresh(false);
                return responseData;
            }
        } catch (error) {
            console.error('Error en la solicitud: ', error);
            let err = error.response.status
            let msg = error.response.data.err
            Swal.fire({
                icon: 'error',
                title: 'No podemos eliminar estanotificacion. Inténtalo de nuevo más tarde',
                text: `No.error: ${err} mensaje: ${msg}`,
                customClass: {
                    container: 'Content_Swal_All'
                }
            });
        }
    };

    /*------------------------------------------------ contenedores visuales ver y no ver-------------------------------------------------*/

    const clock = (e) => {
        document.getElementById("allCalendario_").style.display = "flex";
    }
    const dataBase = () => {
        document.getElementById("allData").style.display = "flex";
    }
    const [nombre, setNombre] = useState('');
    const [plantilla, setPlantilla] = useState('');
    const [vista, setVista] = useState('');

    /*------------------------------------------------ usuario logeado -------------------------------------------------*/

    const [userData, setUserData] = useState(); // Initialize userData state with null
    useEffect(() => {
        // Function to fetch user data
        const fetchUserData = async () => {
            try {
                const response = await Axios.get(GET_URL_USER);
                const fetchedUserData = response.data.users;
                const userToDisplay = fetchedUserData.find(user => user.id === idUser);
                setUserData(userToDisplay);
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };

        // Call the function when the component mounts
        fetchUserData();
    }, [idUser]);

    /*------------------------------------------------ ayudas visuales para los botones -------------------------------------------------*/

    const Tarjeta1 = () => {
        const [isHovered, setIsHovered] = useState(false);

        const handleMouseEnter = () => {
            setIsHovered(true);
        };

        const handleMouseLeave = () => {
            setIsHovered(false);
        };

        return (
            <div style={{ position: 'relative' }}>
                <FaPlay
                    className={`iconCardTamplate1 ${isHovered ? 'hovered' : ''}`}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                />

                {isHovered && (
                    <div className="hoverIcon">
                        <p className='textHoverIcon'>Probar</p>
                    </div>
                )}
            </div>
        );
    };

    const Tarjeta = ({ data }) => {
        const [isHovered, setIsHovered] = useState(false);

        const handleMouseEnter = () => {
            setIsHovered(true);
        };

        const handleMouseLeave = () => {
            setIsHovered(false);
        };

        return (
            <div style={{ position: 'relative' }}>
                <BsDatabaseExclamation
                    className={`iconCardTamplate2_1 ${isHovered ? 'hovered' : ''}`}
                    onClick={(e) => {
                        dataBase(e);
                        const id = data.id;
                        localStorage.setItem('codigoGenerate', id);
                        setNombre(data.nombre);
                        if (data.id_data_vista === '') {
                            setVista("Agrega una vista");
                        } else {
                            setVista(data.id_data_vista);
                        }
                    }}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                />
                {isHovered && (
                    <div className="hoverIcon">
                        <p className='textHoverIcon'>Base de datos</p>
                    </div>
                )}
            </div>
        );
    };

    const Tarjeta2 = ({ data }) => {
        const [isHovered, setIsHovered] = useState(false);

        const handleMouseEnter = () => {
            setIsHovered(true);
        };

        const handleMouseLeave = () => {
            setIsHovered(false);
        };

        return (
            <div style={{ position: 'relative' }}>
                <BsDatabaseCheck
                    className={`iconCardTamplate6_1 ${isHovered ? 'hovered' : ''}`}
                    onClick={(e) => {
                        dataBase(e);
                        const id = data.id;
                        localStorage.setItem('codigoGenerate', id);
                        setNombre(data.nombre);
                        setPlantilla(data.id_Plantilla);
                        if (data.id_data_vista === '') {
                            setVista("Agrega una vista");
                        } else {
                            setVista(data.id_data_vista);
                        }
                    }}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                />
                {isHovered && (
                    <div className="hoverIcon">
                        <p className='textHoverIcon'>Base de datos</p>
                    </div>
                )}
            </div>
        );
    };

    const Tarjeta3 = ({ data }) => {
        const [isHovered, setIsHovered] = useState(false);

        const handleMouseEnter = () => {
            setIsHovered(true);
        };

        const handleMouseLeave = () => {
            setIsHovered(false);
        };

        return (
            <div style={{ position: 'relative' }}>
                <AiOutlineClockCircle
                    className={`iconCardTamplate6 ${isHovered ? 'hovered' : ''}`}
                    onClick={(e) => {
                        clock(e);
                        const id = data.id;
                        localStorage.setItem('codigoGenerate', id);
                        if (data.calendario === '' || data.calendario === null || data.calendario === " ") {
                            setNombre("nothing");
                        } else {
                            setNombre(data.calendario);
                        }
                    }}
                    value={data.id}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                />
                {isHovered && (
                    <div className="hoverIcon">
                        <p className='textHoverIcon'>Calendario</p>
                    </div>
                )}
            </div>
        );
    };

    const Tarjeta4 = ({ data }) => {
        const [isHovered, setIsHovered] = useState(false);

        const handleMouseEnter = () => {
            setIsHovered(true);
        };

        const handleMouseLeave = () => {
            setIsHovered(false);
        };

        return (
            <div style={{ position: 'relative' }}>
                <AiOutlineClockCircle
                    className={`iconCardTamplate6_1 ${isHovered ? 'hovered' : ''}`}
                    onClick={(e) => {
                        clock(e);
                        const id = data.id;
                        localStorage.setItem('codigoGenerate', id);
                        if (data.calendario === '' || data.calendario === null || data.calendario === " ") {
                            setNombre("nothing");
                        } else {
                            setNombre(data.calendario);
                        }
                    }}
                    value={data.id}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                />
                {isHovered && (
                    <div className="hoverIcon">
                        <p className='textHoverIcon'>Calendario</p>
                    </div>
                )}
            </div>
        );
    };

    const Tarjeta7 = ({ data }) => {
        const [isHovered, setIsHovered] = useState(false);

        const handleMouseEnter = () => {
            setIsHovered(true);
        };

        const handleMouseLeave = () => {
            setIsHovered(false);
        };

        return (
            <div style={{ position: 'relative' }}>
                <AiOutlineDelete
                    className={`iconCardTamplate3 ${isHovered ? 'hovered' : ''}`}
                    onClick={() => handleEliminarNotificacion(data.id)}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                />
                {isHovered && (
                    <div className="hoverIcon">
                        <p className='textHoverIcon'>Eliminar</p>
                    </div>
                )}
            </div>
        );
    };

    /*------------------------------------------------ pueba de notificaciones -------------------------------------------------*/

    const seeprueba = async () => {
        try {
            const confirmacion = await Swal.fire({
                title: "¿Estás seguro?",
                text: "Estas a punto de enviar una prueba del mensaje, los numeros a los que estan dirijido estan en datos",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Sí, probar",
                customClass: {
                    container: 'Content_Swal_All'
                }
            });

            if (confirmacion.isConfirmed) {
                const id = parseInt(localStorage.getItem("codigoGenerate"), 10)
                console.log('ID', id)
                const data = {
                    id: id
                };
                const response = await Axios.post(TRIGUER_POST_URL_MESSAGE, data);
                const responseData = response.data;
                await Swal.fire({
                    title: "Enviado!",
                    text: "Tu notificación ha sido enviada con éxito. Dale unos segundos para que llegue los datos",
                    icon: "success",
                    customClass: {
                        container: 'Content_Swal_All'
                    }
                });
                setRefresh(false);
                return responseData;
            }
        } catch (error) {
            console.error('Error en la solicitud: ', error);
            await Swal.fire({
                title: "Ups... ha ocurrido un error a enviar, intenta mas tarde!",
                icon: "error",
                timer: 8000,
                timerProgressBar: true,
                customClass: {
                    container: 'Content_Swal_All'
                }
            });
        }
    };

    return (
        <>
            <div className='mainCard'>
                {datos
                    .filter(data => data.id_usuario === idUser)
                    .map((data, key) => (
                        data.delete_logic
                            ? null
                            : data.is_active === false ? (
                                <div className='sonCard_' key={key}>
                                    <div className='datosContentName'>
                                        <p><b>Nombre: </b>{data.nombre} </p>
                                    </div>
                                    <div className='datosContentViews'>
                                        <p><b>Plantilla: </b>{data.id_Plantilla}</p>
                                    </div>
                                    <div className='Contentbtn_'>
                                        <IconoActivacion
                                            isActive={data.is_active}
                                            onClick={async () => {
                                                await handleActivacion(data);
                                            }}
                                        />
                                    </div>
                                </div>
                            ) : (
                                <div className='sonCard' key={key}>
                                    <div className='datosContentName'>
                                        <p><b>Nombre: </b>{data.nombre} </p>
                                    </div>

                                    <div className='datosContentViews'>
                                        <p><b>Plantilla: </b>{data.id_Plantilla}</p>
                                    </div>

                                    <div className='Contentbtn' key={key}>
                                        {data.id_data_vista === undefined || data.id_data_vista === null || data.id_data_vista === '' ? null
                                            : userData && userData.countDemo < 1 ? null
                                                : <div key={key}>
                                                    <div id='btnPruebaclick' onClick={() => {
                                                        seeprueba();
                                                        localStorage.setItem('codigoGenerate', data.id)
                                                    }}>
                                                        <Tarjeta1 key={key} data={data} />
                                                    </div>
                                                </div>
                                        }
                                        <IconoActivacion
                                            isActive={data.is_active}
                                            onClick={async () => {
                                                await handleActivacion(data);
                                            }}
                                        />
                                        {data.cron_active === false
                                            ? (
                                                <Tarjeta3 data={data} />
                                            ) : (
                                                <Tarjeta4 data={data} />
                                            )
                                        }
                                        {data.is_active === false ? (
                                            <AiOutlineProject />
                                        ) : data.id_data_vista === '' ? (
                                            <Tarjeta data={data} />
                                        ) : (
                                            <Tarjeta2 data={data} />
                                        )}

                                        <Tarjeta7 data={data} />
                                    </div>
                                </div>
                            )
                    )
                    )
                }
            </div >
            < div id='allCalendario_' >
                <ActualizarCalen idUser={idUser} nombre={nombre} setRefresh={setRefresh}/>
            </div>
            <div id='allData'>
                <ActualizarNoti nombre={nombre} vista={vista} plantilla={plantilla} setRefresh={setRefresh}/>
            </div>
        </>
    )
}