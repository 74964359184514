/* -------------------- dependencias -------------------- */
import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';

/*------------------- componentes --------------- */
import Axios from '../../../../../Configuracion/Axios/Axios';
import "../../../../Paginas/Configuracion/Configuracion.css";

/*------------------- rutas --------------- */
import { GET_URL_TEMPLATE, DELETE_URL_TEMPLATE, GET_URL_USER } from '../../../../../Constancias/Rutas/Rutas';




/*---------------------------------- logica ---------------------------------------- */

export const EliminarPlatilla = (props) => {

    const back = () => {
        document.getElementById("botonesEleccion").style.display = "flex";
        document.getElementById("EliminarPlatillas").style.display = "none";
    }


    /*-------------------------------------------- traer todos los usuarios --------------------------------------------- */
    const [idUser, setIdUser] = useState('');
    const idUsers = parseInt((idUser), 10);
    const [usuarios, setUsuarios] = useState([]);

    const handleChangeUserUp = (event) => {
        const selecteduseryId = event.target.value;
        setIdUser(selecteduseryId);
    };

    useEffect(() => {
        const fetchUsuarios = async () => {
            try {
                const response = await Axios.get(GET_URL_USER);
                setUsuarios(response.data.users);
            } catch (error) {
                console.error('Error al obtener la lista de usuarios:', error);
            }
        };

        fetchUsuarios();
    }, []);


    /*-------------------------------------------- traer todas las plantillas --------------------------------------------- */
    const [plantillas, setPlantillas] = useState([]);

    useEffect(() => {
        // Validar que idUsers esté definido antes de realizar la solicitud
        if (!idUsers) {
            // console.warn('idUsers no está definido. No se realizará la solicitud de plantillas.');
            return;
        }

        // Hacer la solicitud a la API para obtener las plantillas
        const obtenerPlantillas = async () => {
            try {
                const response = await Axios.get(`${GET_URL_TEMPLATE}?id_usuario=${idUsers}`);
                setPlantillas(response.data.nombrePlantillas);
            } catch (error) {
                console.warn('Error al obtener las plantillas:', error);
            }
        };

        obtenerPlantillas();
    }, [idUsers]);

    /*-------------------------------------------- eliminar la plantilla --------------------------------------------- */

    const [selectedPlantillaId, setSelectedPlantillaId] = useState('');
    const handlePlantillaChange = (e) => {
        const selectedId = e.target.value;
        setSelectedPlantillaId(selectedId);
    };

    const handleSubmitPlantillas = async (e) => {
        e.preventDefault();
        try {
            const response = await Axios.delete(DELETE_URL_TEMPLATE, {
                data: {
                    id_usuario: idUsers,
                    name: selectedPlantillaId
                }
            });

            console.log('Respuesta del servidor:', response.data);

            if (response) {
                Swal.fire({
                    icon: "success",
                    title: "Haz eliminado una plantilla correctamente",
                    showConfirmButton: false,
                    timer: 1500
                });
                setTimeout(function () {
                    window.location.reload();
                }, 1000);
            }
        } catch (error) {
            console.error('Error en la solicitud:', error);
            Swal.fire({
                title: `Ups... hubo un problema, revisa bien los parametros`,
                icon: 'error',
                toast: true,
                position: 'top',
                showConfirmButton: false,
                width: 560,
                timer: 2800,
                stopKeydownPropagation: true,
                showClass: {
                    popup: 'animate__animated animate__fadeInDown'
                },
                hideClass: {
                    popup: 'animate__animated animate__fadeOutUp'
                },
                customClass: {
                    popup: 'Content_Swall_error',
                }
            });
        }
    };



    return (
        <>
            <div>
                <form onSubmit={handleSubmitPlantillas} className='formConfiguracion'>
                    <label htmlFor="id_usuario">Escoge el usuario que quieres que vea esa plantilla:</label>
                    <select
                        id="id_usuarioplantilladel"
                        name="id_usuarioplantilla"
                        value={idUser}
                        onChange={handleChangeUserUp}
                        required
                        className='selectUser'
                    >
                        <option value="" disabled>Seleccionar usuario</option>
                        {usuarios.map(usuario => (
                            <option key={usuario.id} value={usuario.id}>
                                {usuario.nombre}
                            </option>
                        ))}
                    </select>
                    <label htmlFor="id_usuario">Escoge la platilla que quieres eliminar:</label>
                    <select name="plantilla" className='selectUser' required value={selectedPlantillaId} onChange={handlePlantillaChange} >
                        <option value="">Plantillas</option>
                        {plantillas.map((plantilla, index) => (
                            <option key={index} value={plantilla}>{plantilla}</option>
                        ))}
                    </select>
                    <button type="submit">Eliminar plantilla</button>
                </form>
            </div>
            <button className='backForms' onClick={back}>Atras</button>

        </>

    )
}