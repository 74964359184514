/* -------------------- dependencias -------------------- */
import React, { useState } from 'react';
import Swal from 'sweetalert2';
// import Cookies from 'js-cookie';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
/*------------------- componentes --------------- */
import Axios from '../../../Configuracion/Axios/Axios';
/*------------------- Rutas --------------- */
import {
  GET_URL_USER
} from "../../../Constancias/Rutas/Rutas";
/*------------------- imagenes --------------- */

export const Form = (props) => {
  /*---------------------------------- variables ---------------------------------------- */

  //useState recoje los datos
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [messagesLogin, setMessagesLogin] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };
  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  /*---------------------------------- login ---------------------------------------- */

  const putSesionUser = async (active, id) => {
    try {
      const data = {
        season_active: !active
      }
      const response = await Axios.put(`${GET_URL_USER}?id=${id}`, data);
      if (response) {
        Swal.fire({
          title: `¡Bienvenido! Que bueno verte`,
          icon: 'success',
          toast: true,
          position: 'top',
          showConfirmButton: false,
          width: 560,
          timer: 2800,
          stopKeydownPropagation: true,
          showClass: {
            popup: 'animate__animated animate__fadeInDown'
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp'
          },
          customClass: {
            popup: 'Content_Swall_',
          }
        });
        setTimeout(function () {
          window.location.href = '/Dashboard';
        }, 2000);
      }

    } catch (error) {
      console.warn("Tenemos problemas de conexión, ¡intenta más tarde!");
    }
  }

  const getApi = async (e) => {
    e.preventDefault();
    try {
      const response = await Axios.get(GET_URL_USER);
      const userData = response.data.users;
      const userFound = userData.find((data) => data.nombre === username);

      if (userFound) {
        if (!userFound.season_active) {
          if (password === userFound.contrasena) {
            const userObject = {
              nombre: userFound.nombre,
              activo: true,
              auth: userFound.id,
              login: true,
              token: null,
              account_plan_name: "Beta 1.0.0",
              account_plan_user_role: userFound.rol,
              has_paid_plan: false
            };
            putSesionUser(userFound.season_active, userFound.id);
            localStorage.setItem("setId", userFound.id);
            localStorage.setItem("userObject", JSON.stringify(userObject));
          } else {
            setMessagesLogin("Contraseña incorrecta");
            document.getElementById("Text_conectnion").style.display = "flex";
          }
        } else {
          Swal.fire({
            title: `Este usuario tiene sesión activa`,
            text: "Comunicate con tu equipo y revisa quien tiene sesion activa en la plataforma BitMeta. no podrás acceder hasta que se termine la sesión de esa persona",
            // icon: 'info',
            toast: true,
            position: 'top',
            showConfirmButton: true,
            width: 560,
            timer: 15800,
            timerProgressBar: true,
            stopKeydownPropagation: true,
          });
        }
      } else {
        setMessagesLogin("Usuario no registrado");
        document.getElementById("Text_conectnion").style.display = "flex";
      }
    } catch (error) {
      document.getElementById("Text_conectnion").style.display = "flex";
      setMessagesLogin("Error al conectar con la base de datos");
    }
  };

  return (
    <form onSubmit={getApi}>
      <h3>Iniciar sesión</h3>

      <label htmlFor="username">Nombre de usuario</label>
      <input
        type="text"
        placeholder="Nombre"
        id="username"
        value={username}
        onChange={handleUsernameChange}
        required
        autoComplete="username"
      />

      <label htmlFor="password">Contraseña</label>
      <div className="password-input-container">
        <input
          type={showPassword ? 'text' : 'password'}
          placeholder="***********"
          id="password"
          value={password}
          onChange={handlePasswordChange}
          required
          autoComplete="current-password"
        />
        <FontAwesomeIcon
          icon={showPassword ? faEye : faEyeSlash}
          onClick={handleTogglePasswordVisibility}
          className="password-toggle-icon"
        />
      </div>

      <button type="submit">Iniciar</button>

      <div className="social">
        <div className="go"><i className="fab fa-google"></i> Google</div>
        <div className="fb"><i className="fab fa-facebook"></i> Facebook</div>
      </div>
      <div className='Text_conectnion' id='Text_conectnion'>
        <p>{messagesLogin}</p>
      </div>
    </form>
  )
}